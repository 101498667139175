import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Total from '../../components/total'
import Layout from '../../templates/layout'
import useActions from '../../constants';
import ChartPie from '../../components/chartPie'
import ChartHollow from '../../components/ChartHollow'
import ChartBar from '../../components/chartBar'

const Home = () => {
  const { alertMessage, current, handleUrl, loading,
   setAlertMessage, setCurrent, setLoading, dash_id, prog_id, staff_id
   } = useActions();

   const [formData, setFormData] =useState({
    year:0,
    quarter:0,
    })
   const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
   }
   const [total, setTotal] =useState({
        total:0,
        refugee: 0,
        disabled: 0,
        female: 0,
        distribution:0,
        feedback: 0
    })

  const [Statistics, setStatistics] = useState([])
  const [nationalStat, setNationalStat] = useState({
    refugee:0,
    national:0
  })

  const [genderStats, setGenderStats] = useState({
    male:0,
    female:0
  })
  const [totalDisability, setTotalDisability] = useState({
    yes:0,
    no:0
  })

  const GetTotalYouth =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/dash_total/"+dash_id+"&y="+formData.year+"&q="+formData.quarter))
    .then(res=>{
           
           let full = {
             total:res.data.total,
             refugee: res.data.refugee,
             disabled: res.data.disabled,
             female: res.data.females,
             distribution: res.data.distributions,
             feedback: res.data.feedback

           }
           console.log(full);
           setTotal(full)
           setLoading(false)
    })

    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetGenderStats =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/total_by_gender_dash/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
      if(res.data.length !=0){
      const male = parseInt(res.data.filter((item)=>item.gender =="Male")[0].total)
      const female = parseInt(res.data.filter((item)=>item.gender =="Female")[0].total)
         setGenderStats({
          male:male/(male+female) *100,
          female:female/(male+female)*100
        })
      }else{
        setGenderStats({
          male:0,
          female:0
        })
      }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetTotalDisability =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/total_by_disability_dash/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
      if(res.data.length !=0){
        const yes = parseInt(res.data.filter((item)=>item.any_disability =="Yes")[0].total)
        const no = parseInt(res.data.filter((item)=>item.any_disability =="No")[0].total)
          setTotalDisability({
            yes:yes/(no+yes)*100,
            no: no/(no+yes)*100
          })
          
        }else{
          setTotalDisability({
            yes:0,
            no:0
          })
        }
        setLoading(false)

    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetTotalNational =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/total_by_nationality_dash/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
        console.log("national",res);
        if(res.data.length !=0){
          const refugee = parseInt(res.data.filter((item)=>item.residency_status =="Refugee")[0].total)
          const national = parseInt(res.data.filter((item)=>item.residency_status =="Host")[0].total)
          setNationalStat({
            refugee:refugee/(national+refugee)*100,
            national:national/(national+refugee)*100
          })
        }else{
          setNationalStat({
            refugee:0,
            national:0
          })
        }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  
  useEffect(()=>{
    document.title ="Dolph Max | Dashboard";
    GetTotalYouth();
    GetGenderStats()
    GetTotalNational()
    GetTotalDisability()
  },[formData])
  
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
        <h4 class="fw-bold py-3 mb-2" style={{"display":"flex","justifyContent":"space-between"}}><span class="text-muted fw-light">Dashboard /</span>
        <div class="col-lg-3 col-md-12 col-6 mb-0" style={{"display":"flex"}}>
       
        <article class="input-group" style={{"marginBottom":"0"}} >
            <select class="form-select" id="inputGroupSelect01" 
                    onChange={(e)=>handleChange(e)}
                    name={'year'}
            >
                <option value="0" >Yearly</option>
                <option value="0" >All Years</option>
                <option value="1" >Year 1</option>
                <option value="2" >Year 2</option>
                <option value="3" >Year 3</option>
                <option value="4" >Year 4</option>
                <option value="5" >Year 5</option>
            </select>
        </article>
        <article class="input-group" style={{"marginBottom":"0"}} >
              <select class="form-select" id="inputGroupSelect01" 
                      onChange={(e)=>handleChange(e)}
                      name={'quarter'}
                      disabled={(formData.year ==0)? true:false}

              >
                  <option value="0" >Quarterly</option>
                  <option value="0" >All Quarters</option>
                  <option value="1" >Quarter 1</option>
                  <option value="2" >Quarter 2</option>
                  <option value="3" >Quarter 3</option>
                  <option value="4" >Quarter 4</option>
                 
              </select>
          </article>
        </div>
        </h4>
        </div>
      <div class="row">
          <Total name={'Total Reach'} total={total.total}  />
          <Total name={'Females'} total={total.female}  />
          <Total name={'Refugees'} total={total.refugee}  />
          <Total name={'Disabled'} total={total.disabled}  />
          <Total name={'Items'} total={total.distribution}  />
          <Total name={'Feedback'} total={total.feedback}  />

      </div>
      <div class="row">
      <ChartPie 
              title='Gender Disaggregation'
              labels={['Male','Female']}
              data1={genderStats.male} 
              data2={genderStats.female} 
          />
          <ChartHollow 
              title='Project Reach by Nationality'
              labels={['National', 'Refugee']}
              data1={nationalStat.refugee} 
              data2={nationalStat.national} />
          <ChartBar 
               title='Project Reach by Disability'
               label1={'yes'}
               data1={totalDisability.yes} 
               label2={'no'}
               data2={totalDisability.no}
          />
           <ChartPie 
              title='Distributions by recipient category'
              labels={['Male','Female']}
              data1={genderStats.male} 
              data2={genderStats.female} 
          />

           <ChartBar 
               title='Distribution by Items'
               label1={'yes'}
               data1={totalDisability.yes} 
               label2={'no'}
               data2={totalDisability.no}
          />
      </div>
    </Layout>
  )
}

export default Home
