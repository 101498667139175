import React, {useState} from 'react'
      
export default function useActions(){

    const token = localStorage.getItem("token");
    const prog_id = localStorage.getItem("prog_id");
    const dash_id = localStorage.getItem("dash_id");
    const staff_id = localStorage.getItem("staff_id");
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [current, setCurrent] =useState(1)   

    const handleNext=()=>{
        if(step < 6){
            setStep(step + 1)
        }
    }
    const handlePrevious=()=>{
        if(step > 1){
            setStep(step - 1)
        }
    }

    const handleAlert =(redirect)=>{
        setTimeout(() => setAlertMessage(''), 2000);
    }
    
    const handleUrl =(path)=>"http://dolphmax.com/?p="+path+"&token="+token;

    const handleNextPage =()=>{
        setCurrent(current + 1);
    }

    const handlePrevPage =()=>{
        setCurrent(current - 1);
    }

    const handleFirstPage =()=>{
        setCurrent(1);
    }

    const handleLastPage =(total)=>{
        setCurrent(total);
    }

     const handlePage =(id)=>{
        setCurrent(id);
     }

     return { 
        alertMessage, 
        errorMessage,
        setErrorMessage,
        current, 
        handleNext,
        handlePrevious,
        handleAlert, 
        handleFirstPage,
        handleLastPage,
        handleNextPage, 
        handlePage, 
        handlePrevPage, 
        handleUrl, 
        loading,
        setAlertMessage, 
        setCurrent, 
        setLoading,
        setStep,step,
        prog_id,
        dash_id,
        staff_id
     }
}
