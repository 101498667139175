import React from 'react'
import useActions from '../../../constants'

const Index = () => {
  const { alertMessage, formData, handleAlert, handleNext,
    handlePrevious, handleUrl, loading, setAlertMessage, setFormData,
     setLoading, step} = useActions()

     const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
    }
  return (
    <div>index</div>
  )
}

export default Index