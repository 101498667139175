import React from 'react'

const Index = (props) => {
     let pages =[]
     for(let i=1;i<=props.total;i++){

         pages.push(<li class={(i == props.current)? "page-item active":"page-item "}>
         <a class="page-link" href="javascript:void(0);"
              onClick={()=>props.handlePage(i)}
         >{i}</a></li>)
       
     }

     console.log(pages)
  return (
    <nav aria-label="Page navigation" style={{"display":"flex","justifyContent":"center"}}>
    <ul class="pagination">
      <li class="page-item first">
        <a class="page-link" href="javascript:void(0);"
           onClick={props.handleFirstPage}
          ><i class="tf-icon bx bx-chevrons-left"></i
        ></a>
      </li>
      <li class="page-item prev">
        <a class="page-link" href="javascript:void(0);"
        onClick={props.handlePrevPage}
          ><i class="tf-icon bx bx-chevron-left"></i
        ></a>
      </li>
      {
        pages
      }
    
      <li class="page-item next">
        <a class="page-link" href="javascript:void(0);"
        onClick={props.handleNextPage}
          ><i class="tf-icon bx bx-chevron-right"></i
        ></a>
      </li>
      <li class="page-item last">
        <a class="page-link" href="javascript:void(0);"
         onClick={props.handleLastPage}
          ><i class="tf-icon bx bx-chevrons-right"></i
        ></a>
      </li>
    </ul>
  </nav>
  )
}

export default Index