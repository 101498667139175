import React from 'react'

const index = () => {
  return (
    <footer class="content-footer footer bg-footer-theme">
      <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
        <div class="mb-2 mb-md-0">
          ©
          <script>
            document.write(new Date().getFullYear());
          </script>
          , 
          <a href="https://dolphmax.com" target="_blank" class="footer-link fw-bolder">dolphmax.com</a>
        </div>
       
      </div>
    </footer>
  )
}

export default index