import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner';
import Pagination from '../../components/pagination'
import Alert from '../../components/alert/Alert';
import Select from '../../components/select'
import Input from '../../components/input/Input'
import useParams from '../../constants'

const Index = () => {

  const [feedback, setFeedback] = useState([])
  const { alertMessage, current, handleAlert, handleFirstPage, handleLastPage,
   handleNextPage, handlePage, handlePrevPage, handleUrl, loading,
   setAlertMessage, setCurrent, setLoading} = useParams()

   const [total, setTotal] =useState(0)
   const [feedbackMechanism, setFeedbackMechanism] = useState([])
   const [feedbackStatus, setFeedbackStatus] = useState([])

  const handleChange =(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
    console.log("form",formData)
  }

    const [formData, setFormData] =useState({})
  
  const GetFeedback =(e) =>{
      setLoading(true)
      const dash_id = localStorage.getItem("dash_id");

        axios.get(handleUrl('feedback/find_by_dash/'+dash_id+'&g='+current))
      .then(res=>{
          console.log("from api:",res.data);
          setFeedback(res.data.data);
          setCurrent(res.data.pagination.current)
          setTotal(res.data.pagination.total)
          setLoading(false);
      })
      .catch(error=>{
        console.log(error);
        setLoading(false);
      })
  }

  const GetFeedbackMechanism =(e) =>{
    setLoading(true)
      axios.get(handleUrl('feedback_mechanism/find_all'))
    .then(res=>{
       console.log("feedback mech",res.data);
        setFeedbackMechanism(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
    })
  }

  const GetFeedbackStatus =(e) =>{
    setLoading(true)
      axios.get(handleUrl('feedback_status/find_all'))
    .then(res=>{
       console.log("feedback mech",res.data);
        setFeedbackStatus(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
    })
  }

  const handleDelete =(id) =>{
      setLoading(true)
      axios.get(handleUrl('feedback/delete/'+id))
    .then(res=>{
        console.log(res);
        GetFeedback();
        setLoading(false)
        setAlertMessage("Feedback deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }


  useEffect(()=>{
    document.title ="Dolph Max | Feedback"
      GetFeedback();
      GetFeedbackMechanism()
      GetFeedbackStatus()
  },[])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Feedback</h4>
    <Link to={'/feedback/new'}><button type="button" class="btn btn-outline-dark">New feedback</button></Link> 
    <section className='row distribution'>
             
              <div className='col-md-2'>
                <Select title="Filter By Case Status" 
                        dataKeys={feedbackStatus.map((item)=>{ return item.id })} 
                        dataValues={feedbackStatus.map((item)=>{ return item.name })} 
                        handleChange={handleChange}
                        name='case_status_id'
                />
              </div>
              <div className='col-md-3'>
                <Select title="Group/Individual" 
                         dataKeys={['group','individual']} 
                         dataValues={['Group','Individual']}
                        handleChange={handleChange}
                        name='group_individual'
                />
            </div> 
              <div className='col-md-3'>
                <Select title="Mechanism" 
                         dataKeys={feedbackMechanism.map((item)=>{ return item.id })} 
                         dataValues={feedbackMechanism.map((item)=>{ return item.name })} 
                        handleChange={handleChange}
                        name='mechanism_id'
                />
            </div> 
            <div className='col-md-2'>
              <button className='btn btn-dark'>Go</button>
            </div>
            
            <div className='col-md-2'>
                <Select title="Export Data" 
                        dataKeys={["pdf","csv"]} 
                        dataValues={["PDF","CSV"]}
                        handleChange={handleChange}
                        name='export'
                />
            </div> 
          </section>          
    <Alert message={alertMessage} />
    { loading?  <Spinner /> : 
           <table class="table card-table">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Name</th>
                 <th>Client Name</th>
                 <th>Case Status</th>
                 <th>Mechanism</th>
                 <th>Actions</th>
               </tr>
             </thead>
             <tbody class="table-border-bottom-0">
             { feedback.map((item)=>{  
                 return <tr>
                   <td>{ item.id}</td>
                   <td><Link to={"/feedback/"+item.id}>{ item.feedback_category}</Link></td>
                   <td>{ item.client_name||'Anonymous'}</td>
                   <td>{ item.case_status}</td>
                   <td>{ item.mechanism}</td>
                   <td>
                   <div class="dropdown">
                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href={"/feedback/edit/"+item.id}
                    ><i class="bx bx-edit-alt me-1"></i> Edit</a
                  >
                  <a class="dropdown-item" href="javascript:void(0);"
                    onClick={()=>handleDelete(item.id)}
                    ><i class="bx bx-trash me-1"></i> Delete</a
                  >
                </div>
              </div>
                   </td>
                 </tr>
               }) }
               </tbody>
               </table>
             }
             <Pagination 
                current={current} 
                total={total} 
                handleNextPage={handleNextPage} 
                handlePrevPage={handlePrevPage} 
                handleFirstPage={handleFirstPage} 
                handleLastPage={handleLastPage} 
                handlePage={handlePage} 
            />
     </div>
</Layout>
  )
}

export default Index