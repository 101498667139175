import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'

const Statistics = () => {
  const token = localStorage.getItem("token");

  return (
    <Layout>
    <div class="table-responsive text-nowrap">
        <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span>Statistics</h4>
    </div>
  </Layout>
  )
}

export default Statistics