import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const index = ({title, labels, data1, data2}) => {
  return (
    <div class="col-lg-3 col-md-12 col-6 mb-4 stat">
        <h6>{title}</h6>
        <Pie data={{
            labels:labels,
            datasets: [
              {
                label: '%',
                data: [data1, data2],
                backgroundColor: [
                  '#f60029',
                  '#f68f9d',
                ],
                borderWidth: 0,
              },
            ],
        }} />
    </div>
  )
}

export default index