import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const index =({title, labels, data1, data2}) => {

  return (
    <div class="col-lg-3 col-md-12 col-6 mb-4 stat">
        <h6>{title}</h6>
        <Doughnut data={
          {
            labels: labels,
            datasets: [
              {
                label: '% Reach',
                data: [data1, data2],
                backgroundColor: [
                  '#f60029',
                  '#f68f9d',
                ],
              },
            ],
          }
        } />
    </div>
  )
}

export default index