import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'
import Alert from '../../components/alert/Alert';
import Pagination from '../../components/pagination'
import Select from '../../components/select'
import useActions from '../../constants'

const Index = () => {
  const role = localStorage.getItem("role");
  const { alertMessage, current, formData,
    handleAlert, handleChange, handleFirstPage, handleLastPage,
   handleNextPage, handlePage, handlePrevPage, handleUrl, loading,
   setAlertMessage, setCurrent, setFormData, setLoading,
   setTotal, total} = useActions()
  const [traineesData, setTraineesData] = useState([])  
  const [skill, setSkill] =useState([])

  const GetYouth =(e) =>{
    const dash_id = localStorage.getItem("dash_id");

    setLoading(true)
      axios.get(handleUrl('tvet/find_trainees/'+dash_id+'&g='+current+
      "&gender="+formData.gender+"&skill="+formData.skill_id+"&nationality="+formData.nationality+
      "&disability="+formData.disability))
    .then(res=>{
        console.log(res);
        setTraineesData(res.data.data)
        setLoading(false)
        setCurrent(res.data.pagination.current)
        setTotal(res.data.pagination.total)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetSkill =(e) =>{
    setLoading(true)
      axios.get(handleUrl('trade_skill/find_all'))
    .then(res=>{
        console.log("from skill",res.data);
        setSkill(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
    })
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get(handleUrl('yitraining/delete_youth/'+id))
    .then(res=>{
        console.log(res);
        GetYouth();
        setLoading(false)
        setAlertMessage("youth deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }
  
  const handleFilter =(e)=>{
    GetYouth();
  }

  useEffect(()=>{
    document.title ="Dashboard | TVET"
    GetYouth();
    GetSkill()
  },[current])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> TVET</h4>
    <Link to={'/tvet/new'}>
      <button type="button" class="btn btn-outline-dark" style={{"marginRight":"40px"}}>New trainee</button>
    </Link>
    <button type="button" style={{"marginLeft":"65%"}}
          class="btn">Displaying { current} of { total } Results</button>
       <section className='row distribution'>
             <div className='col-md-3'>
             <Select title="Trade/Skill" 
                  dataKeys={skill.map((item)=>{ return item.id })} 
                  dataValues={skill.map((item)=>{ return item.name })} 
                  handleChange={handleChange}
                  name='skill_id'
                  selected={formData.skill_id}
               />
               </div>
               <div className='col-md-2'>
                <Select title="Gender" 
                        dataKeys={["none","male","female"]} 
                        dataValues={["None","Male","Female"]}
                        handleChange={handleChange}
                        name='gender'
                />
              </div>
            <div className='col-md-2'>
                <Select title="Nationality" 
                        dataKeys={["none","host","refugee"]} 
                        dataValues={["None","Host","Refugee"]}
                        handleChange={handleChange}
                        name='nationality'
                />
            </div> 
            <div className='col-md-2'>
                <Select title="Disability status" 
                        dataKeys={["none","yes","no"]} 
                        dataValues={["None","Yes","No"]}
                        handleChange={handleChange}
                        name='disability'
                />
            </div> 
          
            <div className='col-md-1'>
              <button className='btn btn-dark'
               onClick={handleFilter}
              >Go</button>
            </div>
            
            <div className='col-md-2'>
                <Select title="Export Data" 
                        dataKeys={["pdf","csv"]} 
                        dataValues={["PDF","CSV"]}
                        handleChange={handleChange}
                        name='export'
                />
            </div> 
          </section> 
    <Alert message={alertMessage} />
     { loading?  <Spinner /> : 
            <table class="table card-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Fullname</th>
                  <th>Gender</th>
                  <th>Placement type</th>
                  <th>Skill</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              { traineesData.map((item)=>{  
                  return <tr>
                    <td>{ item.id}</td>
                    <td><Link to={"/tvet/"+item.id}>{ item.fullname}</Link></td>
                    <td>{ item.gender}</td>
                    <td>{ item.placement_type}</td>
                    <td>{ item.skill}</td>
                    <td>{ (item.status == 1)? <span class="badge bg-label-success me-1">active</span>:
                    <span class="badge bg-label-success me-1"> inactive</span> }</td>
                    <td>
                    <div class="dropdown">
                 <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                   <i class="bx bx-dots-vertical-rounded"></i>
                 </button>
                 <div class="dropdown-menu">
                   <a class="dropdown-item" href={"/tvet/edit/"+item.id}
                     ><i class="bx bx-edit-alt me-1"></i> Edit</a
                   >
                   {role =="Super_admin"?
                      <a class="dropdown-item" href="javascript:void(0);"
                        onClick={()=>handleDelete(item.id)}
                        ><i class="bx bx-trash me-1"></i> Delete</a
                      >:""}
                 </div>
               </div>
                    </td>
                  </tr>
                }) }
                </tbody>
                </table>
             }
             <Pagination 
                      current={current} 
                      total={total} 
                      handleNextPage={handleNextPage} 
                      handlePrevPage={handlePrevPage} 
                      handleFirstPage={handleFirstPage} 
                      handleLastPage={handleLastPage} 
                      handlePage={handlePage} 
                  />
     </div>
</Layout>
  )
}

export default Index