import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './containers/auth/login/index';
import Forgotpass from './containers/auth/forgotPass/index'
import YitDash from './containers/yitraining/Home'
import Employers from './containers/employers/Employers';
import EmployersNew from './containers/employers/new'
import EmployersItem from './containers/employers/item'
import Statistics from './containers/statistics/statistics';

import Yitraining from './containers/yitraining/yitraining';
import YitrainingNew from './containers/yitraining/new/';
import YitrainingItem from './containers/yitraining/item'
import YitrainingView from './containers/yitraining/view';

import YieDash from './containers/yiemployment/Home'
import Yiemployment from './containers/yiemployment/yiemployment';
import YiemploymentNew from './containers/yiemployment/new'
import YiemploymentItem from './containers/yiemployment/item'
import YiemploymentView from './containers/yiemployment/view';

import AepDash from './containers/aep_learners/Home'
import Aeplearners from './containers/aep_learners/aeplearners';
import AeplearnersNew from './containers/aep_learners/new';
import AeplearnersItem from './containers/aep_learners/item'
import AeplearnersView from './containers/aep_learners/view'

import DistDash from './containers/distributions/Home';
import Distributions from './containers/distributions';
import DistributionsNew from './containers/distributions/new';
import DistributionsItem from './containers/distributions/item'
import DistributionsView from './containers/distributions/view'

import TvetDash from './containers/tvt/Home';
import Tvt from './containers/tvt';
import TvtNew from './containers/tvt/new';
import TvtItem from './containers/tvt/item'
import TvtView from './containers/tvt/view';

import Feedback from './containers/feedback'
import FeedbackNew from './containers/feedback/new'
import FeedbackItem from './containers/feedback/item'
import FeedbackView from './containers/feedback/view'

import Programs from './containers/index/index'
import Users from './containers/users/User'
import UsersNew from './containers/users/new'
import UsersItem from './containers/users/item'
import UsersView from './containers/users/view'
import Reports from './containers/reports/reports'
import Settings from './containers/settings'

const App = () => {

  return (
   
    <BrowserRouter>
    <Routes> 
      
        <Route path="/" exact element={<Login />} />
        <Route path="/programs" exact element={<Programs />} />
        <Route path="/forgot_pass" element={<Forgotpass />} />

        <Route path="/statistics" element={<Statistics />} />
        
        <Route path="/employers" element={<Employers />} />
        <Route path="/employers/new" element={<EmployersNew />} />
        <Route path="/employers/:id" element={<EmployersItem />} />

        <Route path="/yit/home" element={<YitDash />} />
        <Route path="/yitraining" element={<Yitraining />} />
        <Route path="/yitraining/new" element={<YitrainingNew />} />
        <Route path="/yitraining/edit/:id" element={<YitrainingItem />} />
        <Route path="/yitraining/:id" element={<YitrainingView />} />

        <Route path="/yie/home" element={<YieDash />} />
        <Route path="/yiemployment" element={<Yiemployment />} />
        <Route path="/yiemployment/new" element={<YiemploymentNew />} />
        <Route path="/yiemployment/:id" element={<YiemploymentView />} />
        <Route path="/yiemployment/edit/:id" element={<YiemploymentItem />} />
        
        <Route path="/aep/home" element={<AepDash />} />
        <Route path="/aeplearners" element={<Aeplearners />} />
        <Route path="/aeplearners/new" element={<AeplearnersNew />} />
        <Route path="/aeplearners/edit/:id" element={<AeplearnersItem />} />
        <Route path="/aeplearners/:id" element={<AeplearnersView />} />

        <Route path="/staff" element={<Users />} />
        <Route path="/staff/:id" element={<UsersView />} />
        <Route path="/staff/edit/:id" element={<UsersItem />} />
        <Route path="/staff/new" element={<UsersNew />} />
        
        <Route path="/dist/home" element={<DistDash />} />
        <Route path="/distributions" element={<Distributions />} />
        <Route path="/distributions/new" element={<DistributionsNew />} />
        <Route path="/distributions/edit/:id" element={<DistributionsItem />} />
        <Route path="/distributions/:id" element={<DistributionsView />} />

        <Route path="/tvet/home" element={<TvetDash />} />
        <Route path="/tvet" element={<Tvt />} />
        <Route path="/tvet/new" element={<TvtNew />} />
        <Route path="/tvet/edit/:id" element={<TvtItem />} />
        <Route path="/tvet/:id" element={<TvtView />} />

        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedback/new" element={<FeedbackNew />} />
        <Route path="/feedback/edit/:id" element={<FeedbackItem />} />
        <Route path="/feedback/:id" element={<FeedbackView />} />

        <Route path="/reports" element={<Reports/>} />
        <Route path="/settings" element={<Settings/>} />


    </Routes>
      </BrowserRouter>
  )
}

export default App


