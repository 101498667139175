import React from 'react'
import Header from './header/index'
import Footer from './footer/index';
import Aside from './side/index';
import Nav from '../components/nav/index';

const Layout = (props) => {

    let username ="";
    let role ="";
    let tData ="";

    const isTokenExpired =(token)=>{
      const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }

      const jwtDecode = (jwt)=>{
        try {
          const tokenData = JSON.parse(atob(jwt.split(".")[1].replace(/-/g, "+").replace(/_/g, "/")));
        
          return tokenData
        } catch (error) {
          console.error("Error decoding JWT:", error);
        }
      }

      const token = localStorage.getItem("token");
      if(isTokenExpired(token)){
         localStorage.clear();
         window.location ='/';
      }

      tData = jwtDecode(token)
      username = tData.username
      role = tData.role
      let prog_id = tData.program_id
      let dash_name = tData.dash_name
      if(role =='Super_admin'){
        dash_name = localStorage.getItem("dash_name");
        prog_id = localStorage.getItem("prog_id");
      }
      console.log("token",tData);
       
  return (
    <>
       <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
         <Aside dash={dash_name} prog_id={prog_id} role={role}/>
        <div class="layout-page">
          <Nav username={username} role={role} />
          <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                { props.children }
              </div>
            </div>
           <Footer />
            <div class="content-backdrop fade"></div>
          </div>
        </div>
      </div>

      <div class="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  )
}

export default Layout