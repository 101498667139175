import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'
import Alert from '../../components/alert/Alert';
import Pagination from '../../components/pagination'
import Select from '../../components/select'
import Input from '../../components/input/Input'
import useActions from '../../constants'

const Index = () => {
   
  const role = localStorage.getItem("role");
  const [DistributionData, setDistributionData] = useState([])
  const [category, setCategory] =useState([])
  const [items, setItems] =useState([])
  const { alertMessage, current, handleAlert,  handleFirstPage, handleLastPage,
   handleNextPage, handlePage, handlePrevPage, handleUrl, loading,
   setAlertMessage, setCurrent,  setLoading, dash_id} = useActions()

   const [formData, setFormData] =useState({
    item_id:0,
    cat_id:0,
    reciep:"",
  })

  const handleChange =(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
    console.log("form",formData)
 }
   
  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const GetDistributions =(e) =>{
    setLoading(true)
      axios.get(handleUrl('distributions/find_all_items/'+
      dash_id+'&g='+current+'&item_id='+formData.item_id+'&cat_id='+formData.cat_id+
      '&reciep='+formData.reciep))
    .then(res=>{
        console.log("distributions",res.data);
        setDistributionData(res.data.data)
        setCurrent(res.data.pagination.current)
        setTotalPage(res.data.pagination.totalPage)
        setTotal(res.data.pagination.total)

        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const handleFilter=(e)=>{
     GetDistributions();
  }

  const GetCategory =(e) =>{
    setLoading(true)
      axios.get(handleUrl('distribution_category/find_all'))
    .then(res=>{
        console.log("category",res.data);
        setCategory(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetItems =(e) =>{
    setLoading(true)
      axios.get(handleUrl('distribution_items/find_all'))
    .then(res=>{
        console.log("items",res.data.data);
        setItems(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get(handleUrl('distributions/delete_learners/'+id))
    .then(res=>{
        console.log(res);
        GetDistributions();
        setLoading(false)
        setAlertMessage("items deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  useEffect(()=>{
    document.title ="Dashboard | Distributions"
    GetDistributions();
    GetCategory();
    GetItems();
    
  },[current])

  return (
    <Layout>
         <div class="table-responsive text-nowrap">
         <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> Distribution
         </h4>
         <Link to={'/distributions/new'}><button type="button" 
          class="btn btn-outline-dark">New Items</button>
          </Link>
          
            <button type="button" style={{"marginLeft":"65%"}}
          class="btn">Displaying { current} of { total } Results</button>
       <section className='row distribution'>
             <div className='col-md-2'>
                <Input title="Filter By Reciepient" 
                        handleChange={handleChange}
                        name='reciep'
                        type='text'
                />
               </div>
              <div className='col-md-2'>
                <Select title="Type" 
                        dataKeys={category.map((item)=>{ return item.id })} 
                        dataValues={category.map((item)=>{ return item.name })}
                        handleChange={handleChange}
                        name='cat_id'
                />
              </div>
              <div className='col-md-3'>
                <Select title="Items" 
                         dataKeys={items.map((item)=>{ return item.id })} 
                         dataValues={items.map((item)=>{ return item.name })}
                        handleChange={handleChange}
                        name='item_id'
                />
            </div> 
          
          
            <div className='col-md-3'>
              <button className='btn btn-dark'
                onClick={handleFilter}
              >Go</button>
            </div>
            
            <div className='col-md-2'>
                <Select title="Export Data" 
                        dataKeys={["pdf","csv"]} 
                        dataValues={["PDF","CSV"]}
                        handleChange={handleChange}
                        name='export'
                />
            </div> 
          </section>          
         <Alert message={alertMessage} />
         { loading?  <Spinner /> : 
                <table class="table card-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Reciepient</th>
                      <th>Type</th>
                      <th>Items</th>
                      <th>Quantity</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                  { DistributionData.map((item)=>{  
                      return <tr>
                        <td>{ item.id}</td>
                        <td><Link to={"/distributions/"+item.id}>{ item.fullname}</Link></td>
                        <td>{ item.reciepient_type}</td>
                        <td>{ item.name}</td>
                        <td>{ item.quantity}</td>
                        <td>{ item.recieved_on}</td>
                       <td><div class="dropdown">
                     <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                       <i class="bx bx-dots-vertical-rounded"></i>
                     </button>
                     <div class="dropdown-menu">
                       <a class="dropdown-item" href={"/aeplearners/edit/"+item.id}
                         ><i class="bx bx-edit-alt me-1"></i> Edit</a
                       >
                       {role =="Super_admin"&&<a class="dropdown-item" href="javascript:void(0);"
                        onClick={()=>handleDelete(item.id)}
                        ><i class="bx bx-trash me-1"></i> Delete</a>
                        }
                     </div>
                   </div>
                        </td>
                      </tr>
                    }) }
                    </tbody>
                    </table>
                  }
                  <Pagination 
                      current={current} 
                      total={totalPage} 
                      handleNextPage={handleNextPage} 
                      handlePrevPage={handlePrevPage} 
                      handleFirstPage={handleFirstPage} 
                      handleLastPage={handleLastPage} 
                      handlePage={handlePage} 
                  />
          </div>
    </Layout>
  )
}

export default Index