import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Spinner from '../../components/spinner/Spinner'
import Logo from '../../assets/img/logo.jpg'
import Select from '../../components/select/index';
import './index.css'
import useActions from '../../constants'

const Index = () => {
   
  const { alertMessage, current, handleUrl, loading,
   setAlertMessage, setCurrent, setLoading,
   dash_id} = useActions()

   const [formData, setFormData] =useState({})

  const [dash, setDash] = useState([])
  const [programs, setPrograms] = useState([])
  const [district, setDistrict] = useState([])

  const handlechange =(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
    console.log(formData)
    if(e.target.name ==="district_id"){
       GetDash(e.target.value)
       localStorage.setItem("prog_id", formData.prog_id);
    }
    
  }

  const handleSubmit =(e)=>{
    console.log("dash_id",e.target.value)
    localStorage.setItem("dash_id", e.target.value);
    localStorage.setItem("dash_name", dash.filter((item)=>item.id == e.target.value)[0].name);
    switch(formData.prog_id){
      case "1":
        window.location="aep/home";
        break;
      case "2":
        window.location="yie/home";
        break;
      case "3":
        window.location="yit/home";
        break;
      case "4":
        window.location="dist/home";
        break;
      case "5":
        window.location="tvet/home";
        break;
    }
    
  }

  const GetPrograms =() =>{
    setLoading(true)
      axios.get(handleUrl('dashboard/find_all_program/'))
    .then(res=>{
        console.log("from api:",res.data);
        setPrograms(res.data)
        setLoading(false);
    })
    .catch(error=>{
      console.log(error);
      setLoading(false);
    })
  }

  const GetDash =(district_id) =>{
    setLoading(true)
      axios.get(handleUrl('dashboard/find_all_program_dash/'+formData.prog_id+'/'+
      district_id))
    .then(res=>{
        console.log("from api:",res.data);
        setDash(res.data)
        setLoading(false);
    })
    .catch(error=>{
      console.log(error);
      setLoading(false);
    })
  }

  const GetDistrict =(e) =>{
    setLoading(true)
      axios.get(handleUrl('district/find_all'))
    .then(res=>{
        //console.log("from disability",res.data);
        setDistrict(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
    })
  }

  useEffect(()=>{
    document.title ="Dolph Max | Programs";
    GetPrograms()
    GetDistrict()
  },[])
 
  return (
    <div className='box'>
       <a href="index.html" className="app-brand-link gap-2">
            <span className="app-brand-logo demo" style={{"padding":"0"}}>
            <img src={Logo} alt='' width='17%' style={{"marginLeft":"42%"}} />
            </span>
        </a>
        <div class="card-body">
                      { loading? <Spinner />: <form >                  
                     
                          <article class="row distribution">
                              <div className='col-md-4'>
                                <Select title="Programs" 
                                      dataKeys={[...programs.map((item)=> item.id)]} 
                                      dataValues={[...programs.map((item)=>item.name)]}
                                      handleChange={handlechange}
                                      selected={formData.prog_id}
                                      name='prog_id'
                                />
                                </div>
                                <div className='col-md-4'>
                                <Select title="District" 
                                      dataKeys={district.map((item)=>{ return item.id })} 
                                      dataValues={district.map((item)=>{ return item.name })}
                                      handleChange={handlechange}
                                      name='district_id'
                                      selected={formData.district_id}
                                />   
                                </div>
                                
                                <div className='col-md-4'>
                                <label class="input-group-text" for="inputGroupSelect01">Dashboards</label>
                            <select class="form-select" id="inputGroupSelect01"
                                
                                name='dash_id'
                            > <option  >Choose...</option>
                                    { 
                                       dash.map((item)=>{
                                          return (<option onClick={handleSubmit} value={item.id} >{ item.name }</option>)
                                      })
                                    }
                            </select>
                          </div>
                          </article>
                         
                      </form>
                     }
                    </div>
    </div>
  )
}

export default Index