import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../../templates/layout'
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";
import useActions from '../../../constants'

const Index = () => {

  const { handleUrl, loading, setLoading, setTotal, total, dash_id} = useActions()
  const [EmployeeData, setEmployeeData] = useState({})
  const {id} = useParams()

  const [formData, setFormData] =useState({
  })
  
    const GetEmployee =(e) =>{
      setLoading(true)
        axios.get(handleUrl('yiemployment/find_employees_by_id/'+id))
      .then(res=>{
          console.log(res.data);
          setEmployeeData(res.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
      })
    }
  
    useEffect(()=>{
      document.title ="Dashboard | Learner"
      GetEmployee();
    },[])
  return (

    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /Youth in employment/</span> Employee</h4>
    </div>
    
    <article className={'step active'}>
    <h5>Learner</h5>
    <table class="table card-table">
                 <tbody class="table-border-bottom-0">
                    <tr>
                      <th>Fullname:</th>
                      <th>{EmployeeData.fullname}</th>
                    </tr>
                    <tr>
                      <th>Gender:</th>
                      <th>{EmployeeData.gender}</th>
                    </tr>
                    <tr>
                      <th>Email Address:</th>
                      <th>{EmployeeData.email}</th>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <th>{EmployeeData.mobile}</th>
                    </tr>
                    <tr>
                      <th>Date of Birth:</th>
                      <th>{EmployeeData.date_of_birth}</th>
                    </tr>
                    <tr>
                      <th>Date of Enrollement:</th>
                      <th>{EmployeeData.enroled_date}</th>
                    </tr>
                    <tr>
                      <th>Residency Status:</th>
                      <th>{EmployeeData.residency_status}</th>
                    </tr>
                    <tr>
                      <th>Nationality:</th>
                      <th>{ EmployeeData.nationality }</th>
                    </tr>
                    <tr>
                      <th>Marital Status:</th>
                      <th>{EmployeeData.marital_status}</th>
                    </tr>
                    <tr>
                      <th>Settlement:</th>
                      <th>{EmployeeData.settlement}</th>
                    </tr>
                    <tr>
                      <th>Project Area:</th>
                      <th>{ EmployeeData.project_area_id }</th>
                    </tr>
                    <tr>
                      <th>any_disability:</th>
                      <th>{EmployeeData.any_disability}</th>
                    </tr>
                    <tr>
                      <th>Disability:</th>
                      <th>{ EmployeeData.disability }</th>
                    </tr>
                    <tr>
                      <th>Employers Fullname:</th>
                      <th>{EmployeeData.e_fullname}</th>
                    </tr>
                    <tr>
                      <th>Employers Mobile:</th>
                      <th>{EmployeeData.e_telephone}</th>
                    </tr>
                    <tr>
                      <th>Employers Village:</th>
                      <th>{EmployeeData.e_village}</th>
                    </tr>
                    <tr>
                      <th>Employers Parish:</th>
                      <th>{EmployeeData.e_parish}</th>
                    </tr>
                    <tr>
                      <th>Employers SubCounty:</th>
                      <th>{EmployeeData.e_sub_county}</th>
                    </tr>
                    <tr>
                      <th>Youths Other Village</th>
                      <th>{EmployeeData.o_village}</th>
                    </tr>
                    <tr>
                      <th>Youths Other Subcounty</th>
                      <th>{EmployeeData.o_sub_county}</th>
                    </tr>
                    <tr>
                      <th>Next of Kin</th>
                      <th>{EmployeeData.next_of_kin}</th>
                    </tr>
                    </tbody>
                    </table>
            </article>
            </Layout>
  )
}

export default Index