import React from 'react'

const Alert = ({message}) => {

  return <>
        {  message && <div class="alert alert-success" role="alert">
                           { message }
                      </div>
        }
        </>
}

export default Alert