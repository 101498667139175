import React from 'react'

const Index = ({title, dataKeys, dataValues, selected, handleChange,
   name, required=false, validate=false, disabled=false}) => {
    
  return (
    <article className='select_wrapper'>

      <label class="input-group-text" for="inputGroupSelect01">{ title}
      { required && <span className='star_required'>*</span>}
      </label>
    <select class="form-select" id="inputGroupSelect01" 
            onChange={(e)=>handleChange(e)}
            name={name}
            style={{"borderColor":validate && "#f7012a"}}
            disabled={disabled}
      >
        <option  >Choose...</option>
            { 
            dataKeys.map((item, i)=>{
               let sel =""
              if(item == selected){
                sel = "selected"
              }
               return (<option value={item} selected={sel} >{ dataValues[i] }</option>)
              
              })
            }
    </select>
    {validate && <span className='required_message'>{title+" is required"}</span>}

    </article>
    
  )
}

export default Index