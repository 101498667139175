import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'
import useActions from '../../../constants';
import "./new.css"

const Index = () => {

  const { alertMessage, handleAlert, handleNext,
    handlePrevious, handleUrl, loading, setAlertMessage,
     setLoading, step, prog_id, dash_id} = useActions()

    const [category, setCategory] =useState([])
    const [items, setItems] =useState([])
    const [moreItems, setMoreItems] =useState([1])
    const [nationality, setNationality] = useState([])
    const [disability, setDisability] = useState([])
    const [dropDown, setDropDown] =useState("none")

    const [formData, setFormData] = useState({
      category_id:0,
      reciepient:"",
      fullname:"",
      gender:"",
      residency_status:"",
      nationality:"",
      any_disability:"",
      disability:"",
      settlement:"",
      school:"",
      distributed_by:"",
      mobile:"",
      items:"",
      quantity:"",
      date_of_distribution:""

    })
    
    const handleDropDown=(e)=>{
      console.log("hey guys");
      setDropDown("block")
    }
    
    const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
    }
    const handleMoreItems =()=>{
        setMoreItems([...moreItems,1])
        console.log(moreItems)
    }

    const handleLessItems =()=>{
      const p = moreItems.pop()
      setMoreItems([...p])
      console.log(moreItems)
  }
    
    const GetCategory =(e) =>{
      setLoading(true)
        axios.get(handleUrl('distribution_category/find_all'))
      .then(res=>{
          console.log("category",res.data);
          setCategory(res.data.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
      })
    }
    
    const GetItems =(e) =>{
      setLoading(true)
        axios.get(handleUrl('distribution_items/find_all'))
      .then(res=>{
          console.log("items",res.data.data);
          setItems(res.data.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
      })
    }

      const handleChangeMulti =(e)=>{
        setFormData({...formData,[e.target.name]:[...e.target.value]})
        console.log("form",formData)
      }

      const GetNationality =(e) =>{
        setLoading(true)
          axios.get(handleUrl('nationality/find_all'))
        .then(res=>{
          //  console.log("from nationality",res.data);
            setNationality(res.data.data)
            setLoading(false)
            
        })
        .catch(error=>{
          console.log(error);
        })
      }
      
      const GetDisability =(e) =>{
        setLoading(true)
          axios.get(handleUrl('disability/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setDisability(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true)
         axios.post(handleUrl('aeplearners/create/'), formData)
         .then(res=>{
             console.log(res);
             setAlertMessage("Student created Successfully");
             handleAlert();
             setLoading(false);
             setFormData({
              category_id:0,
              reciepient:"",
              fullname:"",
              gender:"",
              residency_status:"",
              nationality:"",
              any_disability:"",
              disability:"",
              settlement:"",
              school:"",
              distributed_by:"",
              mobile:"",
              items:"",
              quantity:"",
              date_of_distribution:""
           })
         })
         .catch(error=>{
           console.log(error);
           setLoading(false)
         })
      }

      useEffect(()=>{
        document.title = 'Dolph max | Distribution';
          GetNationality();
          GetDisability();
          GetCategory();
          GetItems();
      },[moreItems])

  return (
    <Layout>
    <div class="table-responsive text-nowrap">
       <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard / Distributions /</span> New</h4>
    </div>
    <article className={step==1?'step active': 'step'}>
        <h5>Step 1</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">New Distribution </h5>
                      <small class="text-muted float-end">Create new Distribution</small>
                    </div>
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                         <div className='col-md-4'>
                            <Select title="Category" 
                              dataKeys={category.map((item)=>{ return item.id })} 
                              dataValues={category.map((item)=>{ return item.name })}
                              handleChange={handleChange}
                              name='category_id'
                              required='true'
                            />
                         </div>
                         <div className='col-md-6'>
                            <Input title={'Recipient'} 
                              placeholder={'This is the members fullname'} 
                              handleChange={handleChange}
                              onClick={handleDropDown}
                              name={'recipient_id'}
                              type='search'
                              value={formData.recip_id}
                            />
                            <ul className='recip_names' style={{"display":dropDown}}>
                              
                              <li value={9}>okeny</li>
                              <li value={7}>okeny</li>
                              <li value={8}>okeny</li>
                              <li value={4}>okeny</li>
                              <li value={2}>okeny</li>
                            </ul>
                         </div>
                      </section>
                     { (formData.category_id =="3")&&  <><section className='row' >
                         <div className='col-md-6'>
                             <Input title={'Full name'} 
                                    placeholder={'This is the members fullname'} 
                                    handleChange={handleChange}
                                    name={'fullname'}
                                    value={formData.fullname}
                            />
                         </div>
                         <div className='col-md-6'>
                                <Select title="Gender" 
                                    dataKeys={["none","male","female"]} 
                                    dataValues={["None","Male","Female"]}
                                    handleChange={handleChange}
                                    name='gender'
                                    selected={formData.gender}
                                />
                         </div>
                        
                      </section>
                   
                       <section className='row' >
                         <div className='col-md-6'>
                            <Select title="Residency Status" 
                                    dataKeys={["none","host","refugee"]} 
                                    dataValues={["none","host","Refugee"]}
                                    handleChange={handleChange}
                                    name='residency_status'
                                    selected={formData.residency_status}
                            />
                          </div>
                          <div className='col-md-6'>
                            { formData.residency_status =='refugee'? <     Select title="Nationality" 
                                      dataKeys={nationality.map((item)=>{ return item.id })} 
                                      dataValues={nationality.map((item)=>{ return item.country })}
                                      handleChange={handleChange}
                                      name='nationality_id'
                                      selected={formData.nationality_id}
                                />:""
                            }
                            </div>
                        </section>
                        <section className='row' >
                              <div className='col-md-6'>
                                <Select title="Any Disability" 
                                      dataKeys={["yes","no"]} 
                                      dataValues={["Yes","No"]}
                                      handleChange={handleChange}
                                      name='any_disability'
                                      selected={formData.any_disability}
                                />
                               </div>
                               { formData.any_disability =='yes'?(
                               <div className='col-md-6'>
                                    <Select title="Disability Type" 
                                        dataKeys={disability.map((item)=>{ return item.id })} 
                                        dataValues={disability.map((item)=>{ return item.name })}
                                        handleChange={handleChange}
                                        name='disability_id'
                                        selected={formData.disability_id}
                                    />
                                </div>):""
                                }
                            </section>
                       <section className='row' >
                        
                         <div className='col-md-6'>
                                <Input title={'Settlement'} 
                                    placeholder={''} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'settlement'}
                                    value={formData.settlement}

                               />
                         </div>
                         <div className='col-md-6'>
                                <Input title={'School'} 
                                    placeholder={''} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'school'}
                                    value={formData.school}
                               />
                         </div>
                       </section></>
                       }
                      </form>
                     }
                    </div>
                  </div>
                </section>

        <button type="button" class="btn btn-dark" style={{"marginLeft":"92%"}} onClick={handleNext}>Next</button>


      </article>
      <article className={step==2?'step active': 'step'}>
        <h5>Step 2</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                  
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                     

                        <section className='row' >
                              <div className='col-md-6'>  
                                    <Input title={'Distributed By'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'distributed_by'}
                                            value={formData.distributed_by}
                                            required='true'
                                    />
                               </div>
                               <div className='col-md-6'>  
                                    <Input title={'Mobile'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'mobile'}
                                            value={formData.mobile}
                                            required='true'
                                    />
                               </div>
                               </section>
                              
                      <h5>Distributed Items</h5>
                      { moreItems.map((i)=>{
                         
                        return <section className='row' >
                            <div className='col-md-1'>  
                               <button className='btn btn-dark' 
                                      type='button' 
                                      onClick={handleMoreItems}
                                      style={{"marginBottom":"0","marginTop":"30px"}}
                                >+</button>
                            </div>
                            <div className='col-md-4'>  
                               <Select title="Items" 
                                  dataKeys={items.map((item)=>{ return item.id })} 
                                  dataValues={items.map((item)=>{ return item.name })}
                                  handleChange={handleChange}
                                  name={'item_id_'+i}
                                  selected={formData['item_id_'+i]}
                                  required='true'
                               />   
                            </div>
                            <div className='col-md-2'>  
                                    <Input title={'Quantity'} 
                                        placeholder={'This is the identity number'} 
                                        handleChange={handleChange}
                                        name={'quantity_'+i}
                                        type="number"
                                        required='true'
                                    />
                              </div>
                               <div className='col-md-3'>  
                                    <Input title={'Date of Distribution'} 
                                        placeholder={'This is the identity number'} 
                                        handleChange={handleChange}
                                        name={'distribution_date_'+i}
                                        type="date"
                                        value={formData['distribution_date_'+i]}
                                        required='true'
/>
                               </div>
                               <div className='col-md-1'>  
                               <button className='btn btn-dark' 
                                      type='button' 
                                      onClick={handleLessItems}
                                      style={{"marginBottom":"0","marginTop":"30px"}}
                                >-</button>
                            </div>
                               
                        </section>
                        })}
                      </form>
                   
                     }
                    </div>
                  </div>
                </section>
        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
           </div>
           
      </article>
     
      
      <article className={step==3?'step active': 'step'}>
         <h5>Summary</h5>
         <Alert message={alertMessage} />

         <table class="table card-table">
                 <tbody class="table-border-bottom-0">
                    <tr>
                      <th>Fullname:</th>
                      <th>{formData.fullname}</th>
                    </tr>
                    <tr>
                      <th>Enrollment Date:</th>
                      <th>{formData.enrollment_date}</th>
                    </tr>
                    <tr>
                      <th>Gender:</th>
                      <th>{formData.gender}</th>
                    </tr>
                    <tr>
                      <th>Date of Birth:</th>
                      <th>{formData.date_of_birth}</th>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <th>{formData.email}</th>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <th>{formData.mobile}</th>
                    </tr>
                    <tr>
                      <th>Residency Status:</th>
                      <th>{formData.residency_status}</th>
                    </tr>
                    <tr>
                      <th>Nationality:</th>
                      <th>{nationality.map((item)=>{ if(item.id == formData.nationality_id){ return item.country }})}</th>
                    </tr>
                    <tr>
                      <th>National ID Number:</th>
                      <th>{formData.national_id_number}</th>
                    </tr>
                    <tr>
                      <th>Marital Status:</th>
                      <th>{formData.marital_status}</th>
                    </tr>
                    <tr>
                      <th>Any Children:</th>
                      <th>{formData.any_children}</th>
                    </tr>
                    <tr>
                      <th>Biological Children Number:</th>
                      <th>{formData.biological_children_no}</th>
                    </tr>
                    <tr>
                      <th>Dependent Children Number:</th>
                      <th>{formData.dependent_children_no}</th>
                    </tr>
                    <tr>
                      <th>Number of Children:</th>
                      <th>{formData.children_no}</th>
                    </tr>
                    <tr>
                      <th>Age of Youngest:</th>
                      <th>{formData.age_of_youngest}</th>
                    </tr>
                    <tr>
                      <th>Your currently living with:</th>
                      <th>{formData.currently_living_with}</th>
                    </tr>
                    <tr>
                      <th>Any Disability:</th>
                      <th>{formData.any_disability}</th>
                    </tr>
                    <tr>
                      <th>State if yes Disability:</th>
                      <th>{disability.map((item)=>{ if(item.id == formData.disability_id){ return item.name }})}</th>
                    </tr>
                    <tr>
                      <th>How did you hear about AEP:</th>
                      <th>{formData.hear_about}</th>
                    </tr>
                    <tr>
                      <th>Last Class Attended:</th>
                      <th>{formData.last_class_attended}</th>
                    </tr>
                    <tr>
                      <th>AEP level:</th>
                      <th>{formData.aep_level}</th>
                    </tr>
                    <tr>
                      <th>Plans After Completing:</th>
                      <th>{formData.plans_after_completing}</th>
                    </tr>                     
                  </tbody>
            </table>
           <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
      </article>
    </Layout>
  )
}

export default Index