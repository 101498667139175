import React from 'react'

const index = ({message}) => {
  return <>
  {  message && <div class="alert alert-danger" role="alert">
                     { message }
                </div>
  }
  </>
}

export default index