import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const index = ({title, label1, data1, label2, data2, label3, data3}) => {

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          },
        },
      };
      
      const labels = ['marital status'];
      
      const data = {
        labels,
        datasets: [
          {
            label: label1,
            data: [data1],
            backgroundColor: '#f60029',
          },
          {
            label: label2,
            data: [data2],
            backgroundColor: '#f24a61',
          },
          {
            label: label3,
            data: [data3],
            backgroundColor: '#f24a90',
          },
        ],
      };
  return (
    <div class="col-lg-5 col-md-12 col-6 mb-4 stat card">
        <h6>{title}</h6>
        <Bar options={options} data={data} />
    </div>
  )
}

export default index