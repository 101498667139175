import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'
import Pagination from '../../components/pagination'
import useActions from '../../constants'

const User = () => {
  const role = localStorage.getItem("role");
  const { alertMessage, current, handleAlert, handleFirstPage, handleLastPage,
    handleNextPage, handlePage, handlePrevPage, handleUrl, loading,
    setAlertMessage, setCurrent, setLoading, dash_id, prog_id} = useActions()
  const [UserData, setUserData] = useState([])
  const [deleteid, setDeleteID] =useState(0)
  const [total, setTotal] =useState(0)

  const GetUser =(e) =>{
    const dash_id = localStorage.getItem("dash_id");

    setLoading(true)
      axios.get(handleUrl('dash_auth/find_staff/'+dash_id+'&g='+current))
    .then(res=>{
        console.log(res);
        setUserData(res.data.data)
        setLoading(false)
        setCurrent(res.data.pagination.current)
        setTotal(res.data.pagination.total)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get(handleUrl('dash_auth/delete/'+id))
    .then(res=>{
        console.log(res);
        GetUser();
        setLoading(false)
        setAlertMessage("Staff deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }
 
  useEffect(()=>{
    document.title ="Dashboard | User"
    GetUser();
  },[current])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> Staff</h4>
    <Link to={'/staff/new'}><button type="button" class="btn btn-outline-dark">New Staff</button></Link>
    <button type="button" style={{"marginLeft":"68%"}}
          class="btn">Displaying { current} of { total } Results</button>
        
    { loading?  <Spinner /> : 
           <table class="table card-table">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Fullname</th>
                 <th>Email</th>
                 <th>Position</th>
                 <th>Role</th>
                 <th>Status</th>
                 <th>Actions</th>
               </tr>
             </thead>
             <tbody class="table-border-bottom-0">
             { UserData.map((item)=>{  
                 return <tr>
                   <td>{ item.id}</td>
                   <td><Link to={"/staff/"+item.id}>{ item.fullname}</Link></td>
                   <td>{ item.email}</td>
                   <td>{ item.position}</td>
                   <td>{ item.role}</td>
                   <td>{ (item.active == 1)? <span class="badge bg-label-success me-1">active</span>:
                   <span class="badge bg-label-success me-1"> inactive</span> }</td>
                   <td> <div class="dropdown">
                 <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                   <i class="bx bx-dots-vertical-rounded"></i>
                 </button>
                 <div class="dropdown-menu">
                  <a class="dropdown-item" href={"/staff/edit/"+item.id}>
                    <i class="bx bx-edit-alt me-1"></i> Edit</a>
                  <a class="dropdown-item" href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#basicModal"
                      onClick={()=>setDeleteID(item.id)}
                    ><i class="bx bx-trash me-1"></i> Delete</a
                  >
                </div></div>
                   </td>
                 </tr>
               }) }
               </tbody>
               </table>
             }
             <Pagination 
             current={current} 
             total={total} 
             handleNextPage={handleNextPage} 
             handlePrevPage={handlePrevPage} 
             handleFirstPage={handleFirstPage} 
             handleLastPage={handleLastPage} 
             handlePage={handlePage} 
         />

       <div class="modal fade" id="basicModal" tabindex="-1" aria-hidden="true">
         <div class="modal-dialog" role="document">
           <div class="modal-content">
             <div class="modal-body">
                <h5>Delete Account</h5>
                      <div class="mb-3 col-12 mb-0">
                        <div class="alert-warning" style={{"padding":"20px"}}>
                          <h6 class="alert-heading fw-bold mb-1">Are you sure you want to delete this account?</h6>
                          <p class="mb-0">Once you delete your account, there is no going back.
                           Please be certain.</p>
                        </div>
                      </div>
                        <div class="form-check mb-3">
                          <input class="form-check-input" type="checkbox" name="accountActivation" id="accountActivation"/>
                          <label class="form-check-label" for="accountActivation">I confirm my account deactivation</label>
                        </div>
                        <button type="button" class="btn btn-danger deactivate-account"
                          onClick={()=>handleDelete(deleteid)}
                          data-bs-dismiss="modal"
                        >Deactivate Account</button>
                    </div>
                  </div>
             </div>
           </div>
     </div>
</Layout>
)
}


export default User