import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Total from '../../components/total'
import Layout from '../../templates/layout'
import useActions from '../../constants';
import ChartPie from '../../components/chartPie'
import ChartHollow from '../../components/ChartHollow'
import ChartBar from '../../components/chartBar'
import ChartBar2 from '../../components/chatbar2'


const Home = () => {
  const { alertMessage, current, handleUrl, loading,
   setAlertMessage, setCurrent, setLoading, dash_id, prog_id, staff_id
   } = useActions();

   const [formData, setFormData] =useState({
    year:0,
    quarter:0,
    })
   const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
   }
   const [total, setTotal] =useState({
        total:0,
        refugee: 0,
        disabled: 0,
        female: 0,
        distribution:0,
        feedback: 0
    })

  const [Statistics, setStatistics] = useState([])
  const [nationalStat, setNationalStat] = useState({
    refugee:0,
    national:0
  })
  const [aepLevels, setAEPLevels] = useState({
    level1:0,
    level2:0
  })

  const [parents, setParents] = useState({
    yes:0,
    no:0
  })

  const [maritalStatus, setMaritalStatus] = useState({
    single:0,
    married:0,
    divorced:0
  })

  const [genderStats, setGenderStats] = useState({
    male:0,
    female:0
  })
  const [totalDisability, setTotalDisability] = useState({
    yes:0,
    no:0
  })

  const GetTotalYouth =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/dash_total/"+dash_id+"&y="+formData.year+"&q="+formData.quarter))
    .then(res=>{
           
           let full = {
             total:res.data.total,
             refugee: res.data.refugee,
             disabled: res.data.disabled,
             female: res.data.females,
             distribution: res.data.distributions,
             feedback: res.data.feedback

           }
           console.log(full);
           setTotal(full)
           setLoading(false)
    })

    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetGenderStats =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/total_by_gender_dash/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
      if(res.data.length !=0){
      const male = parseInt(res.data.filter((item)=>item.gender =="Male")[0].total)
      const female = parseInt(res.data.filter((item)=>item.gender =="Female")[0].total)
         setGenderStats({
          male:male/(male+female) *100,
          female:female/(male+female)*100
        })
      }else{
        setGenderStats({
          male:0,
          female:0
        })
      }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetTotalDisability =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/total_by_disability_dash/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
      if(res.data.length !=0){
        const yes = parseInt(res.data.filter((item)=>item.any_disability =="Yes")[0].total)
        const no = parseInt(res.data.filter((item)=>item.any_disability =="No")[0].total)
          setTotalDisability({
            yes:yes/(no+yes)*100,
            no: no/(no+yes)*100
          })
          
        }else{
          setTotalDisability({
            yes:0,
            no:0
          })
        }
        setLoading(false)

    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetTotalNational =(e) =>{
    setLoading(true)
      axios.get(handleUrl("statistics/total_by_nationality_dash/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
        console.log("national",res);
        if(res.data.length !=0){
          const refugee = parseInt(res.data.filter((item)=>item.residency_status =="Refugee")[0].total)
          const national = parseInt(res.data.filter((item)=>item.residency_status =="Host")[0].total)
          setNationalStat({
            refugee:refugee/(national+refugee)*100,
            national:national/(national+refugee)*100
          })
        }else{
          setNationalStat({
            refugee:0,
            national:0
          })
        }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetAEPlevels =(e) =>{
    setLoading(true)
      axios.get(handleUrl("aeplearners/stat_aep_levels/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
        console.log("aep levels",res);
        if(res.data.length !=0){
          const l1 = parseInt(res.data.filter((item)=>item.aep_level =="AEP Level 1 (S1 & S2)")[0].total)
          const l2 = parseInt(res.data.filter((item)=>item.aep_level =="AEP Level 2 (S3 & S4)")[0].total)
          setAEPLevels({
            level1:l1/(l1+l2)*100,
            level2:l2/(l1+l2)*100
          })
        }else{
          setAEPLevels({
            level1:0,
            level2:0
          })
        }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetParents =(e) =>{
    setLoading(true)
      axios.get(handleUrl("aeplearners/stat_youth_with_children/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
        console.log("parents",res);
        if(res.data.length !=0){
          const yes = parseInt(res.data.filter((item)=>item.any_children =="Yes")[0].total)
          const no = parseInt(res.data.filter((item)=>item.any_children =="No")[0].total)
          setParents({
            yes:yes/(yes+no)*100,
            no:no/(yes+no)*100
          })
        }else{
          setParents({
            yes:0,
            no:0
          })
        }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetMaritalStatus =(e) =>{
    setLoading(true)
      axios.get(handleUrl("aeplearners/stat_marital_status/"+dash_id+"&y="+formData.year+'&q='+formData.quarter))
    .then(res=>{
        console.log("marital status",res);
        if(res.data.length !=0){
          const single = parseInt(res.data.filter((item)=>item.marital_status =="Single")[0].total)
          const married = parseInt(res.data.filter((item)=>item.marital_status =="Living together/Married")[0].total)
          const divorced = parseInt(res.data.filter((item)=>item.marital_status =="Divorced/Separated")[0].total)

          setMaritalStatus({
            single:single/(single+married+divorced)*100,
            married:married/(single+married+divorced)*100,
            divorced:divorced/(single+married+divorced)*100,
          })
        }else{
          setMaritalStatus({
            single:0,
            married:0,
            divorced:0
          })
        }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  
  useEffect(()=>{
    document.title ="Dolph Max | Dashboard";
    GetTotalYouth();
    GetGenderStats()
    GetTotalNational()
    GetTotalDisability()
    GetAEPlevels()
    GetMaritalStatus()
    GetParents()
  },[formData])
  
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
        <h4 class="fw-bold py-3 mb-2" style={{"display":"flex","justifyContent":"space-between"}}><span class="text-muted fw-light">Dashboard /</span>
        <div class="col-lg-4 col-md-12 col-6 mb-0" style={{"display":"flex"}}>
       
        <article class="input-group" style={{"marginBottom":"0"}} >
            <select class="form-select" id="inputGroupSelect01" 
                    onChange={(e)=>handleChange(e)}
                    name={'year'}
            >
                <option value="0" >Yearly</option>
                <option value="0" >All Years</option>
                <option value="1" >Year 1</option>
                <option value="2" >Year 2</option>
                <option value="3" >Year 3</option>
                <option value="4" >Year 4</option>
                <option value="5" >Year 5</option>
            </select>
        </article>
        <article class="input-group" style={{"marginBottom":"0"}} >
              <select class="form-select" id="inputGroupSelect01" 
                      onChange={(e)=>handleChange(e)}
                      name={'quarter'}
                      disabled={(formData.year ==0)? true:false}

              >
                  <option value="0" >Quarterly</option>
                  <option value="0" >All Quarters</option>
                  <option value="1" >Quarter 1</option>
                  <option value="2" >Quarter 2</option>
                  <option value="3" >Quarter 3</option>
                  <option value="4" >Quarter 4</option>
                 
              </select>
          </article>
        </div>
        </h4>
        </div>
      <div class="row">
          <Total name={'Total Reach'} total={total.total}  />
          <Total name={'Females'} total={total.female}  />
          <Total name={'Refugees'} total={total.refugee}  />
          <Total name={'Disabled'} total={total.disabled}  />
          <Total name={'Feedback'} total={total.feedback}  />
          <Total name={'Distribution'} total={total.distribution}  />


      </div>
      <div class="row">
          <ChartPie 
              title='Gender Disaggregation'
              labels={['Male','Female']}
              data1={genderStats.male} 
              data2={genderStats.female} 
          />
          <ChartHollow 
              title='Project Reach by Nationality'
              labels={['National', 'Refugee']}
              data1={nationalStat.refugee} 
              data2={nationalStat.national} />
          <ChartBar 
               title='Project Reach by Disability'
               label1={'yes'}
               data1={totalDisability.yes} 
               label2={'no'}
               data2={totalDisability.no}
          />
          <ChartHollow 
              title='Disegregation by AEP Levels'
              labels={['AEP Level 1 (S1 & S2)', 'AEP Level 2 (S3 & S4)']}
              data1={aepLevels.level1} 
              data2={aepLevels.level2}
           />
           <ChartBar2
               title='Distribution by Marital Status'
               label1={'Single'}
               data1={maritalStatus.single} 
               label2={'Divorced/Separated'}
               data2={maritalStatus.divorced}
               label3={'Living together/Married'}
               data3={maritalStatus.married}
          />
           <ChartPie 
              title='Youth with Children'
              labels={['Yes','No']}
              data1={parents.yes} 
              data2={parents.no} 
          />

      </div>
    </Layout>
  )
}

export default Home