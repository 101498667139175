import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../templates/layout'
import useActions from '../../constants'

const Index = () => {
    
  const { alertMessage, current, formData,
    handleAlert, handleUrl, loading,
   setAlertMessage, setLoading, dash_id} = useActions()

    const handleDeleteAll =(id) =>{
        setLoading(true)
          axios.get(handleUrl('dashboard/clear_all'))
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Data deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteAEP =(id) =>{
        setLoading(true)
          axios.get(handleUrl('dashboard/delete/'+id))
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Feedback deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteYIT =(id) =>{
        setLoading(true)
          axios.get(handleUrl('yitraining/clear_all'))
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Youth in training deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteYIW =() =>{
        setLoading(true)
          axios.get(handleUrl('yiemployment/clear_all'))
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Youth in works deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteTVET =(id) =>{
        setLoading(true)
          axios.get(handleUrl('tvet/clear_data/'))
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("TVET deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteDistribution =(id) =>{
        setLoading(true)
          axios.get(handleUrl('distriution/clear_data/'))
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Distribution deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
        <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span>Settings</h4>
    </div>
    <section class="col-xll">
                  <div class="card mb-4">
                  <div class="card-header d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">Settings</h5>
                      <small class="text-muted float-end">Create settings for dashboards</small>
                    </div>
                    <div class="card-body">
        <article>
           
</article>
            
        </div>
        </div>
        </section>
    
  </Layout>
  )
}

export default Index