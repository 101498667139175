import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../../templates/layout'
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";
import useActions from '../../../constants'

const Index = () => {
  const { handleUrl, loading, setAlertMessage, setCurrent, setLoading,
   setTotal, total, dash_id} = useActions()

    const [LearnerData, setLearnerData] = useState({})
    const {id} = useParams()


  
    const GetLearner =(e) =>{
      setLoading(true)
        axios.get(handleUrl('yitraining/find_trainees_by_id/'+id))
      .then(res=>{
          console.log(res.data);
          setLearnerData(res.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
      })
    }
  
    useEffect(()=>{
      document.title ="Dashboard | Learner"
      GetLearner();
    },[])
  return (

    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /Youth in training/</span> Learner</h4>
    </div>
    
    <article className={'step active'}>
    <h5>Learner</h5>
    <table class="table card-table">
                 <tbody class="table-border-bottom-0">
                    <tr>
                      <th>Fullname:</th>
                      <th>{LearnerData.fullname}</th>
                    </tr>
                    <tr>
                      <th>Gender:</th>
                      <th>{LearnerData.gender}</th>
                    </tr>
                    <tr>
                      <th>Date of Birth:</th>
                      <th>{LearnerData.date_of_birth}</th>
                    </tr>
                    <tr>
                      <th>Marital Status:</th>
                      <th>{LearnerData.marital_status}</th>
                    </tr>
                    <tr>
                      <th>Email Address:</th>
                      <th>{LearnerData.email}</th>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <th>{LearnerData.mobile}</th>
                    </tr>
                    <tr>
                      <th>Residency Status:</th>
                      <th>{LearnerData.residency_status}</th>
                    </tr>
                    <tr>
                      <th>Nationality:</th>
                      <th>{LearnerData.nationality}</th>
                    </tr>
                    <tr>
                      <th>National ID Number:</th>
                      <th>{LearnerData.national_id_number}</th>
                    </tr>
                    <tr>
                      <th>District:</th>
                      <th>{ LearnerData.district }</th>
                    </tr>
                    <tr>
                      <th>Sub County:</th>
                      <th>{LearnerData.sub_county}</th>
                    </tr>
                    <tr>
                      <th>Parish:</th>
                      <th>{LearnerData.parish}</th>
                    </tr>
                    <tr>
                      <th>Village:</th>
                      <th>{LearnerData.village}</th>
                    </tr>
                    <tr>
                      <th>Trade Skill:</th>
                      <th>{ LearnerData.skill}</th>
                    </tr>
                    <tr>
                      <th>Placement Type:</th>
                      <th>{ LearnerData.placement_type }</th>
                    </tr>
                    <tr>
                      <th>Placement Location:</th>
                      <th>{LearnerData.placement_location}</th>
                    </tr>
                    <tr>
                      <th>Placement length:</th>
                      <th>{ LearnerData.placement_length }</th>
                    </tr>
                    
                    <tr>
                      <th>Any Disability:</th>
                      <th>{LearnerData.any_disability}</th>
                    </tr>
                    <tr>
                      <th>Disability:</th>
                      <th>{ LearnerData.disability }</th>
                    </tr>
                   
                  </tbody>
            </table>
            </article>
            </Layout>
  )
}

export default Index