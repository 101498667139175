import React from 'react'

const Input = ({title, value, placeholder, handleChange, name, type='text',required=false, validate=false}) => {
  return (
    <article >
    <label htmlFor="title" class="form-label">{ title }
      { required && <span className='star_required'>*</span>}
    </label>
        <input
          type={type}
          class="form-control"
          id="title"
          name={name}
          placeholder={ placeholder }
          value={value}
          onChange={(e)=>handleChange(e)}
          style={{"borderColor":validate && "#f7012a"}}

        />
        {validate && <span className='required_message'>{title+" is required"}</span>}
      </article>
  )
}

export default Input