import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";
import useActions from '../../../constants';

const Index = () => {
    
  const { alertMessage, current, formData,
    handleAlert, handleChange, handleNext, handlePrevious, handleUrl, loading,
   setAlertMessage, setCurrent, setFormData, setLoading,
   setTotal, step, dash_id} = useActions()
    const {id} = useParams()

    const [skill, setSkill] = useState([])
    const [placement, setPlacement] = useState([])
    const [plength, setPlength] = useState([])
    const [nationality, setNationality] = useState([])
    const [disability, setDisability] = useState([])
    const [district, setDistrict] = useState([])

    const GetLearner =(e) =>{
        setLoading(true)
          axios.get(handleUrl('tvet/find_trainees_by_id/'+id))
        .then(res=>{
            console.log(res.data);
            setFormData(res.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }
   
      const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true)
         axios.post(handleUrl('tvet/update/'+id), formData)
         .then(res=>{
             console.log(res);
             setAlertMessage("Trainee updated Successfully");
             handleAlert();
             setLoading(false);
         })
         .catch(error=>{
           console.log(error);
           setLoading(false)
         })
      }

      const GetDisability =(e) =>{
        setLoading(true)
          axios.get(handleUrl('disability/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setDisability(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetDistrict =(e) =>{
        setLoading(true)
          axios.get(handleUrl('district/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setDistrict(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetNationality =(e) =>{
        setLoading(true)
          axios.get(handleUrl('nationality/find_all'))
        .then(res=>{
          //  console.log("from nationality",res.data);
            setNationality(res.data.data)
            setLoading(false)
            
        })
        .catch(error=>{
          console.log(error);
        })
      }


      const GetSkill =(e) =>{
        setLoading(true)
          axios.get(handleUrl('trade_skill/find_all'))
        .then(res=>{
            console.log("from skill",res.data);
            setSkill(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetPlength =(e) =>{
        setLoading(true)
          axios.get(handleUrl('placement_length/find_all'))
        .then(res=>{
            console.log("from plength",res.data);
            setPlength(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetPlacement =(e) =>{
        setLoading(true)
          axios.get(handleUrl('placement_type/find_all'))
        .then(res=>{
            console.log("from placement",res.data);
            setPlacement(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }
    
      useEffect(()=>{
        document.title = 'Dolph max | TVET Edit';
          GetSkill();
          GetPlacement()
          GetPlength();
          GetNationality();
          GetDisability();
          GetDistrict();
          GetLearner()
      },[])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
       <h4 class="fw-bold py-3 mb-2">
        <span class="text-muted fw-light">Dashboard / Technical Vocational Educational Training /</span> Edit
      </h4>
    </div>
    <article className={step==1?'step active': 'step'}>
        <h5>Step 1</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">Edit Youth</h5>
                      <small class="text-muted float-end">Update a TVET Youth</small>
                    </div>
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                         <div className='col-md-6'>
                             <Input title={'Full name of the youth'} 
                                    placeholder={'This is the members fullname'} 
                                    handleChange={handleChange}
                                    name={'fullname'}
                                    value={formData.fullname}
                            />
                         </div>

                         <div className='col-md-6'>
                         <Select title="Gender" 
                                      dataKeys={["male","female"]} 
                                      dataValues={["Male","Female"]}
                                      handleChange={handleChange}
                                      name='gender'
                                      selected={formData.gender}
                                />
                        
                         </div>
                      </section>
                      <section className='row' >
                         <div className='col-md-6'>
                               <Input title={'Date of birth'} 
                                    placeholder={'Your age'} 
                                    handleChange={handleChange}
                                    type='date'
                                    name={'date_of_birth'}
                                    value={formData.date_of_birth}
                               />
                         </div>
                           
                         <div className='col-md-6'>
                         <Select title="marital status" 
                                        dataKeys={["single","living together/married","Divorced/Separeted","widowed"]} 
                                        dataValues={["Single","Living together/married","Divorced/Separeted","Widowed"]}
                                        handleChange={handleChange}
                                        name='marital_status'
                                        selected={formData.marital_status}
                                    />
                         
                         </div>
                       </section>
                       <section className='row' >
                         <div className='col-md-6'>
                               <Input title={'Email'} 
                                    placeholder={'Your email address'} 
                                    handleChange={handleChange}
                                    type='email'
                                    name={'email'}
                                    value={formData.email}
                               />
                         </div>
                           
                         <div className='col-md-6'>
                                <Input title={'Mobile Number'} 
                                    placeholder={'+256756961542'} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'mobile'}
                                    value={formData.mobile}
                               />
                         </div>
                       </section>
                      </form>
                     }
                    </div>
                  </div>
                </section>

        <button type="button" class="btn btn-dark" style={{"marginLeft":"92%"}} onClick={handleNext}>Next</button>
      </article>
      <article className={step==2?'step active': 'step'}>
        <h5>Step 2</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                  
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                      <div className='col-md-6'>
                            <Select title="Residency Status" 
                                    dataKeys={["none","host","refugee"]} 
                                    dataValues={["none","host","Refugee"]}
                                    handleChange={handleChange}
                                    name='residency_status'
                                    selected={formData.residency_status}
                            />
                          </div>
                          { formData.residency_status =='refugee'?
                          <div className='col-md-6'>
                                <Select title="Nationality" 
                                      dataKeys={nationality.map((item)=>{ return item.id })} 
                                      dataValues={nationality.map((item)=>{ return item.country })}
                                      handleChange={handleChange}
                                      name='nationality_id'
                                      selected={formData.nationality_id}
                                />    
                          </div>:""
                         }
                        </section>
                        <section className='row' >
                        <div className='col-md-6'>
                                <Input title={'National ID Number'} 
                                    placeholder={'This is the NIN number'} 
                                    handleChange={handleChange}
                                    type='number'
                                    name={'national_id_number'}
                                    value={formData.national_id_number}
                                />
                                </div>
                      <div className='col-md-6'>
                         <Select title="District" 
                                      dataKeys={district.map((item)=>{ return item.id })} 
                                      dataValues={district.map((item)=>{ return item.name })}
                                      handleChange={handleChange}
                                      name='district_id'
                                      selected={formData.district_id}
                                />   
                          </div>
                          </section>
                          <section className='row'>
                            <div className='col-md-6'>
                                <Input title={'Sub County'} 
                                    placeholder={'This is the NIN number'} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'sub_county'}
                                    value={formData.sub_county}
                                />
                                </div>
                                <div className='col-md-6'>
                               <Input title={'Parish'} 
                                    placeholder={'This is the NIN number'} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'parish'}
                                    value={formData.parish}
                                />
                                </div>
                        </section>
                        <section className='row' >
                            <div className='col-md-6'>
                                <Input title={'Village'} 
                                    placeholder={'This is the learners village'} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'village'}
                                    value={formData.village}
                                />
                                </div>
                        </section>
                      </form>
                     }
                    </div>
                  </div>
                </section>
            <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
            </div>
      </article>
      <article className={step==3?'step active': 'step'}>
        <h5>Step 3</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                  
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                               <div className='col-md-6'>  
                               <Select title="Trade/Skill" 
                                    dataKeys={skill.map((item)=>{ return item.id })} 
                                    dataValues={skill.map((item)=>{ return item.name })} 
                                    handleChange={handleChange}
                                    name='trade_skill_id'
                                    selected={formData.trade_skill_id}
                               />
                                </div>
                                <div className='col-md-6'>  
                                <Select title="Placement type" 
                                        dataKeys={placement.map((item)=>{ return item.id })} 
                                        dataValues={placement.map((item)=>{ return item.name })} 
                                        handleChange={handleChange}
                                        name='placement_type_id'
                                        selected={formData.placement_type_id}
                                    />
                               </div>
                            </section>
                            <section className='row' >
                               <div className='col-md-6'>  
                                    <Input title={'location of placement'} 
                                      placeholder={'This is the location of training'} 
                                      handleChange={handleChange}
                                      name={'placement_location'}
                                      value={formData.placement_location}
                                    />
                                </div>
                                <div className='col-md-6'>  
                                <Select title="Length of training" 
                                        dataKeys={plength.map((item)=>{ return item.id })} 
                                        dataValues={plength.map((item)=>{ return item.name })} 
                                        handleChange={handleChange}
                                        name='placement_length_id'
                                        selected={formData.placement_length_id}
                                    />
                               </div>
                            </section>
                      <section className='row' >
                              <div className='col-md-6'>
                                <Select title="Any Disability" 
                                      dataKeys={["yes","no"]} 
                                      dataValues={["Yes","No"]}
                                      handleChange={handleChange}
                                      name='any_disability'
                                      selected={formData.any_disability}
                                />
                               </div>
                               <div className='col-md-6'>
                                    <Select title="Disability Type" 
                                        dataKeys={disability.map((item)=>{ return item.id })} 
                                        dataValues={disability.map((item)=>{ return item.name })}
                                        handleChange={handleChange}
                                        name='disability_id'
                                        selected={formData.disability_id}
                                    />
                                </div>
                            </section>
                        </form>
                     }
                    </div>
                  </div>
                </section>
           <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
           </div>
      </article>    
      <article className={step==4?'step active': 'step'}>
         <h5>Summary</h5>
         <Alert message={alertMessage} />
         <table class="table card-table">
                 <tbody class="table-border-bottom-0">
                    <tr>
                      <th>Fullname:</th>
                      <th>{formData.fullname}</th>
                    </tr>
                    <tr>
                      <th>Gender:</th>
                      <th>{formData.gender}</th>
                    </tr>
                    <tr>
                      <th>Date of Birth:</th>
                      <th>{formData.date_of_birth}</th>
                    </tr>
                    <tr>
                      <th>Marital Status:</th>
                      <th>{formData.marital_status}</th>
                    </tr>
                    <tr>
                      <th>Email Address:</th>
                      <th>{formData.email}</th>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <th>{formData.mobile}</th>
                    </tr>
                    <tr>
                      <th>Residency Status:</th>
                      <th>{formData.residency_status}</th>
                    </tr>
                    <tr>
                      <th>Nationality:</th>
                      <th>{nationality.map((item)=>{ if(item.id == formData.nationality_id){ return item.country }})}</th>
                    </tr>
                    <tr>
                      <th>National ID Number:</th>
                      <th>{formData.national_id_number}</th>
                    </tr>
                    <tr>
                      <th>District:</th>
                      <th>{district.map((item)=>{ if(item.id == formData.district_id){ return item.name }})}</th>
                    </tr>
                    <tr>
                      <th>Sub County:</th>
                      <th>{formData.sub_county}</th>
                    </tr>
                    <tr>
                      <th>Parish:</th>
                      <th>{formData.parish}</th>
                    </tr>
                    <tr>
                      <th>Village:</th>
                      <th>{formData.village}</th>
                    </tr>
                    <tr>
                      <th>Trade Skill:</th>
                      <th>{skill.map((item)=>{if(item.id == formData.trade_skill_id){ return item.name}})}</th>
                    </tr>
                    <tr>
                      <th>Placement Type:</th>
                      <th>{placement.map((item)=>{ if(item.id == formData.placement_type_id){ return item.name }})}</th>
                    </tr>
                    <tr>
                      <th>Placement Location:</th>
                      <th>{formData.placement_location}</th>
                    </tr>
                    <tr>
                      <th>Placement length:</th>
                      <th>{plength.map((item)=>{ if(item.id == formData.placement_length_id){ return item.name}})}</th>
                    </tr>
                    
                    <tr>
                      <th>Any Disability:</th>
                      <th>{formData.any_disability}</th>
                    </tr>
                    <tr>
                      <th>Disability:</th>
                      <th>{disability.map((item)=>{ if(item.id == formData.disability_id){ return item.name }})}</th>
                    </tr>
                   
                  </tbody>
            </table>
           <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
      </article>
    </Layout>
  )
}

export default Index