import React from 'react'
import {Link, useHistory ,useLocation } from 'react-router-dom';

import Logo from '../../assets/img/logo.jpg';

const index = ({dash,prog_id, role}) => {
  
console.log("role ",role)
  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
    <div className="app-brand demo">
      <Link to="/" className="app-brand-link">
        <span className="app-brand-logo demo">
        <img src={Logo} alt='' width='55%' style={{'margin':'20px 30px'}} />
           <h4 style={{"color":"#fff","lineHeight":"28px","textAlign":"center"
}}>{dash}</h4>
        </span>
      </Link>

      <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i className="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>
    <div className="menu-inner-shadow"></div>
    <ul className="menu-inner py-1">
      
      { (role =="Super_admin")&&  <li className="menu-item ">
        <Link to="/programs" className="menu-link">
          <i className="menu-icon tf-icons bx bx-copy"></i>
          <div data-i18n="Analytics">Programs</div>
        </Link>
      </li>
     }
      { (prog_id==1) && <><li className="menu-item">
                      <Link to="/aep/home" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-home-circle"></i>
                        <div data-i18n="Analytics">Dashboard</div>
                      </Link>
                    </li>
                    <li className="menu-item ">
                      <Link to="/aeplearners" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-copy"></i>
                        <div data-i18n="Analytics">AEP Learners</div>
                      </Link>
                    </li></>}
          { (prog_id==2)&&<>
                    <li className="menu-item">
                      <Link to="/yie/home" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-home-circle"></i>
                        <div data-i18n="Analytics">Dashboard</div>
                      </Link>
                    </li>
                     <li className="menu-item ">
                      <Link to="/yiemployment" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-copy"></i>
                        <div data-i18n="Analytics">Youth in Works</div>
                      </Link>
                     </li>
                     <li className="menu-item ">
                        <Link to="/employers" className="menu-link">
                          <i className="menu-icon tf-icons bx bx-copy"></i>
                          <div data-i18n="Analytics">Employers</div>
                        </Link>
                      </li>
                    </>}
         { (prog_id==3)&& <><li className="menu-item">
                      <Link to="/yit/home" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-home-circle"></i>
                        <div data-i18n="Analytics">Dashboard</div>
                      </Link>
                    </li><li className="menu-item ">
                      <Link to="/yitraining" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-copy"></i>
                        <div data-i18n="Analytics">Youth in Training</div>
                      </Link>
                    </li> </>}
          {(prog_id==4)&&<><li className="menu-item">
                      <Link to="/dist/home" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-home-circle"></i>
                        <div data-i18n="Analytics">Dashboard</div>
                      </Link>
                    </li><li className="menu-item ">
                      <Link to="/distributions" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-copy"></i>
                        <div data-i18n="Analytics">Distribution</div>
                      </Link>
                    </li></>}
          { (prog_id==5)&&<><li className="menu-item">
                      <Link to="/tvet/home" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-home-circle"></i>
                        <div data-i18n="Analytics">Dashboard</div>
                      </Link>
                    </li>
                    <li className="menu-item ">
                     <Link to="/tvet" className="menu-link">
                       <i className="menu-icon tf-icons bx bx-copy"></i>
                       <div data-i18n="Analytics">TVET</div>
                     </Link>
                   </li></>
         }
      <li className="menu-item ">
        <Link to="/feedback" className="menu-link">
          <i className="menu-icon tf-icons bx bx-copy"></i>
          <div data-i18n="Analytics">Feedback</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/staff" className="menu-link">
          <i className="menu-icon tf-icons bx bx-lock-open-alt"></i>
          <div data-i18n="Analytics">Staff</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/settings" className="menu-link">
          <i className="menu-icon tf-icons bx bx-lock-open-alt"></i>
          <div data-i18n="Analytics">Settings</div>
        </Link>
      </li>
    </ul>
  </aside>
  )
}

export default index