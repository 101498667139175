import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../../templates/layout'
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";
import useActions from '../../../constants'

const Index = () => {

    const [LearnerData, setLearnerData] = useState({})
    const {id} = useParams()
    const { handleUrl, setLoading, step} = useActions()
  
    const GetLearner =(e) =>{
      setLoading(true)
        axios.get(handleUrl('aeplearners/find_learners_by_id/'+id))
      .then(res=>{
          console.log(res.data.fullname);
          setLearnerData(res.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
      })
    }
  
    useEffect(()=>{
      document.title ="Dashboard | Learner"
      GetLearner();
    },[])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /AEP Learners/</span> Learner</h4>
    </div>
    
    <article className={'step active'}>
    <h5>Learner</h5>
    <table class="table card-table">
            <tbody class="table-border-bottom-0">
               <tr>
                 <th>Fullname:</th>
                 <th>{LearnerData.fullname}</th>
               </tr>
               <tr>
                 <th>Enrollment Date:</th>
                 <th>{LearnerData.enrollment_date}</th>
               </tr>
               <tr>
                 <th>Gender:</th>
                 <th>{LearnerData.gender}</th>
               </tr>
               <tr>
                 <th>Date of Birth:</th>
                 <th>{LearnerData.date_of_birth}</th>
               </tr>
               <tr>
                 <th>Email:</th>
                 <th>{LearnerData.email}</th>
               </tr>
               <tr>
                 <th>Mobile:</th>
                 <th>{LearnerData.mobile}</th>
               </tr>
               <tr>
                 <th>Residency Status:</th>
                 <th>{LearnerData.residency_status}</th>
               </tr>
               <tr>
                 <th>Nationality:</th>
                 <th>{LearnerData.country}</th>
               </tr>
               <tr>
                 <th>National ID Number:</th>
                 <th>{LearnerData.national_id_number}</th>
               </tr>
               <tr>
                 <th>Marital Status:</th>
                 <th>{LearnerData.marital_status}</th>
               </tr>
               <tr>
                 <th>Any Children:</th>
                 <th>{LearnerData.any_children}</th>
               </tr>
               <tr>
                 <th>Biological Children Number:</th>
                 <th>{LearnerData.biological_children_no}</th>
               </tr>
               <tr>
                 <th>Dependent Children Number:</th>
                 <th>{LearnerData.dependent_children_no}</th>
               </tr>
               <tr>
                 <th>Number of Children:</th>
                 <th>{LearnerData.children_no}</th>
               </tr>
               <tr>
                 <th>Age of Youngest:</th>
                 <th>{LearnerData.age_of_youngest}</th>
               </tr>
               <tr>
                 <th>Your currently living with:</th>
                 <th>{LearnerData.living_with}</th>
               </tr>
               <tr>
                 <th>Any Disability:</th>
                 <th>{LearnerData.any_disability}</th>
               </tr>
               <tr>
                 <th>State if yes Disability:</th>
                 <th>{LearnerData.disability}</th>
               </tr>
               <tr>
                 <th>How did you hear about AEP:</th>
                 <th>{LearnerData.hear_about}</th>
               </tr>
               <tr>
                 <th>Last Class Attended:</th>
                 <th>{LearnerData.last_class_attended}</th>
               </tr>
               <tr>
                 <th>AEP level:</th>
                 <th>{LearnerData.aep_level}</th>
               </tr>
               <tr>
                 <th>Plans After Completing:</th>
                 <th>{LearnerData.plans_after_completing}</th>
               </tr>                     
             </tbody>
       </table>
     
 </article>
 </Layout>
  )
}

export default Index