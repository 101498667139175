import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'
import Textarea from '../../../components/textarea';
import useActions from '../../../constants';

const Index = () => {
  const { alertMessage, current, handleAlert, handleNext, handlePage, handlePrevious, handleUrl, loading,
   setAlertMessage, setLoading, step, prog_id, dash_id} = useActions()

    const [validate, setValidate] = useState(false)
    const [nationality, setNationality] = useState([])
    const [feedbackMechanism, setFeedbackMechanism] = useState([])
    const [feedbackGroupCat, setFeedbackGroupCat] = useState([])
    const [district, setDistrict] = useState([])

    const [formData, setFormData] =useState({
      mechanism:"",
      date_of_complaint:"",
      group_indivdual:"",
      group_category:"",
      anonymous:"",
      client_name:"",
      mobile:"",
      gender:"",
      residency_status:"",
      nationality_id:"",
      age:"",
      region:"",
      district_id:"",
      settlement:"",
      any_disability:"",
      disability_id:"",
      complaint_category:"",
      action_taken:"",
      conserned_project:"",
      agreed_action:"",
      dateline:"",
      staff_responsible:"",
      case_status:"",
      complaint_description:'',
      dash_id,
      prog_id
    })

    const [disability, setDisability] = useState([])
    const [feedbackCategory, setFeedbackCategory] = useState([])
    const [feedbackAction, setFeedbackAction] = useState([])
    const [feedbackProject, setFeedbackProject] = useState([])
    const [feedbackStatus, setFeedbackStatus] = useState([])


    const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
   }

    const handleValidate1=()=>{
      if(formData.fullname !="" && formData.enrollment_date !="" &&
        formData.gender !="" && formData.date_of_birth){
          handleNext();
      }
      validate =true;
    }

      const GetFeedbackMechanism =(e) =>{
        setLoading(true)
          axios.get(handleUrl('feedback_mechanism/find_all'))
        .then(res=>{
           console.log("feedback mech",res.data);
            setFeedbackMechanism(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetFeedbackAction =(e) =>{
        setLoading(true)
          axios.get(handleUrl('feedback_action/find_all'))
        .then(res=>{
           console.log("feedback mech",res.data);
            setFeedbackAction(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetFeedbackStatus =(e) =>{
        setLoading(true)
          axios.get(handleUrl('feedback_status/find_all'))
        .then(res=>{
           console.log("feedback mech",res.data);
            setFeedbackStatus(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetFeedbackGroupCat =(e) =>{
        setLoading(true)
          axios.get(handleUrl('feedback_group_category/find_all'))
        .then(res=>{
           //console.log("feedback category",res.data);
            setFeedbackGroupCat(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }
      
      const GetDistrict =(e) =>{
        setLoading(true)
          axios.get(handleUrl('district/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setDistrict(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }
      const GetNationality =(e) =>{
        setLoading(true)
          axios.get(handleUrl('nationality/find_all'))
        .then(res=>{
          //  console.log("from nationality",res.data);
            setNationality(res.data.data)
            setLoading(false)
            
        })
        .catch(error=>{
          console.log(error);
        })
      }

      
      const GetDisability =(e) =>{
        setLoading(true)
          axios.get(handleUrl('disability/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setDisability(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetComplaintCategory =(e) =>{
        setLoading(true)
          axios.get(handleUrl('feedback_category/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setFeedbackCategory(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetFeedbackProject =(e) =>{
        setLoading(true)
          axios.get(handleUrl('feedback_project/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setFeedbackProject(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true)
         axios.post(handleUrl('feedback/create/'), formData)
         .then(res=>{
             console.log(res);
             setFormData({
              mechanism:"",
              date_of_complaint:"",
              group_indivdual:"",
              group_category:"",
              anonymous:"",
              client_name:"",
              mobile:"",
              gender:"",
              residency_status:"",
              nationality_id:"",
              age:"",
              region:"",
              district_id:"",
              settlement:"",
              any_disability:"",
              disability_id:"",
              complaint_category:"",
              action_taken:"",
              conserned_project:"",
              agreed_action:"",
              dateline:"",
              staff_responsible:"",
              case_status:"",
              complaint_description:'',
              dash_id,
              prog_id
             })
             setAlertMessage("Feedback created Successfully");
             handleAlert();
             setLoading(false);
             
         })
         .catch(error=>{
           console.log(error);
           setLoading(false)
         })
      }

      useEffect(()=>{
        document.title = 'Dolph max | Feedback';
          GetNationality();
          GetDisability();
          GetFeedbackMechanism();
          GetFeedbackGroupCat();
          GetDistrict();
          GetComplaintCategory();
          GetFeedbackAction();
          GetFeedbackProject();
          GetFeedbackStatus();
      },[])

  return (
    <Layout>
    <div class="table-responsive text-nowrap">
       <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard / Feedback /</span> New</h4>
    </div>
    <article className={step==1?'step active': 'step'}>
        <h5>Step 1</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">New Feedback </h5>
                      <small class="text-muted float-end">Report a complaint </small>
                    </div>
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                         <div className='col-md-6'>
                                <Select title="How was feedback got?" 
                                      dataKeys={feedbackMechanism.map((item)=>{ return item.id })} 
                                      dataValues={feedbackMechanism.map((item)=>{ return item.name })} 
                                      handleChange={handleChange}
                                      name='mechanism'
                                      selected={formData.mechanism}
                                      required='true'
                                />
                         </div>
                         <div className='col-md-6'>
                               <Input title={'Date of Complaint'} 
                                    placeholder={'This is the date of recieving the complaint'} 
                                    handleChange={handleChange}
                                    type='date'
                                    name={'date_of_complaint'}
                                    value={formData.date_of_complaint}
                                    required='true'
                            />
                         </div>
                      </section>
                      <section className='row' >
                      <div className='col-md-6'>
                             <Select title="Individual or group complaint" 
                                      dataKeys={["individual","group"]} 
                                      dataValues={["Individual","Group"]}
                                      handleChange={handleChange}
                                      name='group_indivdual'
                                      selected={formData.group_individual}
                                      required='true'
                                />
                         </div>
                         <div className='col-md-6'>

                                <Select title="If group then category" 
                                      dataKeys={feedbackGroupCat.map((item)=>{ return item.id })} 
                                      dataValues={feedbackGroupCat.map((item)=>{ return item.name })} 
                                      handleChange={handleChange}
                                      name='group_category'
                                      selected={formData.group_category}
                                      required='true'
                                      disabled={!(formData.group_indivdual =='group')? true:false}
                                />
                         </div>
                       </section>
                       <section className='row' >
                         <div className='col-md-6'>
                         <Select title="Request to stay Anonymous" 
                                      dataKeys={["none","yes","no"]} 
                                      dataValues={["None","Yes","No"]}
                                      handleChange={handleChange}
                                      name='anonymous'
                                      selected={formData.anonymous}
                                      required='true'
                                />
                         </div>
                         <div className='col-md-6'>
                          {(formData.anonymous =='no')?
                                <Input title={'Name of the Client'} 
                                    placeholder={'client name'} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'client_name'}
                                    value={formData.client_name}
                               />:'' }
                         </div>
                       </section>
                      </form>
                     }
                    </div>
                  </div>
                </section>

        <button type="button" class="btn btn-dark" style={{"marginLeft":"92%"}} onClick={handleNext}>Next</button>


      </article>
      <article className={step==2?'step active': 'step'}>
        <h5>Step 2</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                  
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                     { (formData.anonymous =='no')?
                     <>
                      <section className='row' >
                      <div className='col-md-6'>
                                <Input title={'Telephone mobile'} 
                                    placeholder={'+256756961542'} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'mobile'}
                                    value={formData.mobile}
                               />
                         </div>
                         <div className='col-md-6'>
                                <Select title="Gender" 
                                      dataKeys={["male","female"]} 
                                      dataValues={["Male","Female"]}
                                      handleChange={handleChange}
                                      name='gender'
                                      selected={formData.gender}
                                      required='true'
                                />
                         </div>
                        
                        </section>
                        <section className='row' >
                        <div className='col-md-6'>
                            <Select title="Residency Status" 
                                    dataKeys={["host","refugee"]} 
                                    dataValues={["Host","Refugee"]}
                                    handleChange={handleChange}
                                    name='residency_status'
                                    required='true'
                                    selected={formData.residency_status}
                            />
                          </div>
                          <div className='col-md-6'>
                            { formData.residency_status =='refugee'? <Select title="Nationality" 
                                      dataKeys={nationality.map((item)=>{ return item.id })} 
                                      dataValues={nationality.map((item)=>{ return item.country })}
                                      handleChange={handleChange}
                                      name='nationality_id'
                                      selected={formData.nationality_id}
                                />:""
                            }
                            </div>
                              <div className='col-md-6'>  
                                    <Input title="Age" 
                                        handleChange={handleChange}
                                        name='age'
                                        type={'number'}
                                        required='true'
                                    />
                               </div>
                               <div className='col-md-6'>  
                                    <Select title="Region" 
                                        dataKeys={["south western","west nile"]} 
                                        dataValues={["South western","West nile"]}
                                        handleChange={handleChange}
                                        name='region'
                                        selected={formData.region}
                                        required='true'
                                    />
                                </div>
                            </section>
                            </>:<p>Skip this page since the user wants to be anonymous</p>}
                            </form>
                     }
                    </div>
                  </div>
                </section>
        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
           </div>
           
      </article>
      <article className={step==3?'step active': 'step'}>
        <h5>Step 3</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                        <section className='row' >
                              <div className='col-md-6'>
                                <Select title="District" 
                                      handleChange={handleChange}
                                      name='district_id'
                                      dataKeys={district.map((item)=>{ return item.id })} 
                                      dataValues={district.map((item)=>{ return item.name })}
                                      selected={formData.district_id}
                                      required='true'
                                />
                            </div>
                            <div className='col-md-6'>
                               <Input title={'Settlement'} 
                                    placeholder={''} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'settlement'}
                                    value={formData.settlement}
                                />
                            </div>
                        </section>
                        <section className='row' >
                        <section className='row' >
                              <div className='col-md-6'>
                                <Select title="Any Disability" 
                                      dataKeys={["yes","no"]} 
                                      dataValues={["Yes","No"]}
                                      handleChange={handleChange}
                                      name='any_disability'
                                      selected={formData.any_disability}
                                      required='true'
                                />
                               </div>
                               { formData.any_disability =='yes'?(
                               <div className='col-md-6'>
                                    <Select title="Disability Type" 
                                        dataKeys={disability.map((item)=>{ return item.id })} 
                                        dataValues={disability.map((item)=>{ return item.name })}
                                        handleChange={handleChange}
                                        name='disability_id'
                                        selected={formData.disability_id}
                                    />
                                </div>):""
                                }
                            </section>
                         </section>
                      </form>
                     }
                    </div>
                  </div>
                </section>
        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
        </div>
      </article>
      <article className={step==4?'step active': 'step'}>
         <h5>Step 4</h5>
         <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                            <section className='row'>
                              <Textarea title="Description of the complaint" 
                                    name="complaint_description" 
                                    handleChange={handleChange}
                                    value={formData.complaint_description}
                                />
                            </section>
                            <section className='row' >
                              <div className='col-md-6'>
                                   <Select title={'Complaint Category'}
                                        dataValues={feedbackCategory.map((item)=>{ return item.name })} 
                                        dataKeys={feedbackCategory.map((item)=>{ return item.id })} 
                                        handleChange={handleChange}
                                        name='complaint_category'
                                        selected={formData.complaint_category}
                                    />
                              </div>
                              <div className='col-md-6'>
                                   <Select title={'Action taken by the staff'}
                                      dataValues={feedbackAction.map((item)=>{ return item.name })} 
                                      dataKeys={feedbackAction.map((item)=>{ return item.id })} 
                                        handleChange={handleChange}
                                        name='action_taken'
                                        selected={formData.action_taken}
                                    />
                              </div>
                            </section>
                            <section className='row' >
                             
                              <div className='col-md-6'>
                                   <Select title={'Project the complaint relates to'}
                                        dataValues={feedbackProject.map((item)=>{ return item.name })} 
                                        dataKeys={feedbackProject.map((item)=>{ return item.id })} 
                                        handleChange={handleChange}
                                        name='conserned_project'
                                        selected={formData.conserned_project}
                                        required='true'
                                    />
                              </div>
                            </section>

                        </form>
                     }
                    </div>
                  </div>
                </section>
         <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
        </div>
      </article>
      <article className={step==5?'step active': 'step'}>
         <h5>Step 5</h5>
         <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                            <section className='row'>
                              <Textarea title="Agreed Action to be taken" 
                                  name="agreed_action" 
                                  handleChange={handleChange}
                                  value={formData.agreed_action}
                              />
                            </section>
                            <section className='row' >
                              <div className='col-md-6'>
                               <Input title={'Dateline for the action'} 
                                    placeholder={'Only Children less than 18years born of the learner'} 
                                    handleChange={handleChange}
                                    type='date'
                                    name={'dateline'}
                                    value={formData.dateline}
                                />
                              </div>
                              <div className='col-md-6'>
                                   <Input title={'Staff responsible for action'}
                                        handleChange={handleChange}
                                        name='staff_responsible'
                                        selected={formData.staff_responsible}
                                    />
                              </div>
                            </section>
                            <section className='row' >
                              <div className='col-md-6'>
                                   <Select title={'Case Status'}
                                         dataKeys={feedbackStatus.map((item)=>{ return item.id })} 
                                         dataValues={feedbackStatus.map((item)=>{ return item.name })} handleChange={handleChange}
                                        name='case_status'
                                        selected={formData.case_status}
                                    />
                              </div>
                            
                            </section>

                        </form>
                     }
                    </div>
                  </div>
                </section>
         <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
        </div>
      </article>
      <article className={step==6?'step active': 'step'}>
         <h5>Summary</h5>
         <Alert message={alertMessage} />

         <table class="table card-table">
                 <tbody class="table-border-bottom-0">
                    <tr>
                      <th>Mechanism:</th>
                      <th>{formData.mechanism}</th>
                    </tr>
                    <tr>
                      <th>Date of Complaint:</th>
                      <th>{formData.date_of_complaint}</th>
                    </tr>
                    <tr>
                      <th>Group or Individual Complaint:</th>
                      <th>{formData.group_indivdual}</th>
                    </tr>
                    <tr>
                      <th>Group Category:</th>
                      <th>{formData.group_category}</th>
                    </tr>
                    <tr>
                      <th>Anonymous:</th>
                      <th>{formData.anonymous}</th>
                    </tr>
                    <tr>
                      <th>Client Name:</th>
                      <th>{formData.client_name}</th>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <th>{formData.mobile}</th>
                    </tr>
                    <tr>
                      <th>Gender:</th>
                      <th>{formData.gender}</th>
                    </tr>
                    <tr>
                      <th>Residency Status:</th>
                      <th>{formData.residency_status}</th>
                    </tr>
                    <tr>
                      <th>Age:</th>
                      <th>{formData.age}</th>
                    </tr>
                    <tr>
                      <th>Region:</th>
                      <th>{formData.region}</th>
                    </tr>
                    <tr>
                      <th>Settlement:</th>
                      <th>{formData.settlement}</th>
                    </tr>
                    <tr>
                      <th>Any Disability:</th>
                      <th>{formData.dependent_children_no}</th>
                    </tr>
                    <tr>
                      <th>Complaint Category:</th>
                      <th>{formData.complaint_category}</th>
                    </tr>
                    <tr>
                      <th>Action Taken:</th>
                      <th>{formData.action_taken}</th>
                    </tr>
                    <tr>
                      <th>Conserned Project:</th>
                      <th>{formData.conserned_project}</th>
                    </tr>
                    <tr>
                      <th>Agreed Action:</th>
                      <th>{formData.agreed_action}</th>
                    </tr>
                    <tr>
                      <th>Dateline:</th>
                      <th>{ formData.dateline }</th>
                    </tr>
                    <tr>
                      <th>Staff Responsible:</th>
                      <th>{formData.staff_responsible}</th>
                    </tr>
                    <tr>
                      <th>Case Status:</th>
                      <th>{formData.case_status}</th>
                    </tr>
                                   
                  </tbody>
            </table>
           <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
      </article>
    </Layout>
  )
}

export default Index