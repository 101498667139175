import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'
import Alert from '../../components/alert/Alert';
import Pagination from '../../components/pagination'
import Select from '../../components/select'
import useActions from '../../constants'

const Yiemployment = () => {
  const role = localStorage.getItem("role");
  const { alertMessage, current, handleAlert, handleFirstPage, handleLastPage,
   handleNextPage, handlePage, handlePrevPage, handleUrl, loading,
   setAlertMessage, setCurrent, setLoading, dash_id, prog_id} = useActions()
   const [total, setTotal] =useState(0)
  const [UserData, setUserData] = useState([])
  const [projectArea, setProjectArea] = useState([])
  
  const [formData, setFormData] =useState({
    gender:"",
    nationality:"",
    disability:"",
    project_area:""
  })

  const handleChange =(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
    console.log("form",formData)
 }

  const GetEmployee =(e) =>{
    setLoading(true)
      axios.get(handleUrl('yiemployment/find_employees/'+dash_id+'&g='+current+
      "&gender="+formData.gender+"&nationality="+formData.nationality+"&disability="+formData.disability+
      "&proj_area="+formData.project_area))
    .then(res=>{
        console.log(res);
        setUserData(res.data.data)
        setLoading(false)
        setCurrent(res.data.pagination.current)
        setTotal(res.data.pagination.total)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetProjectArea =(e) =>{
    setLoading(true)
      axios.get(handleUrl('yiemployment/find_project_area'))
    .then(res=>{
        console.log(res);
        setProjectArea(res.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const handleFilter =(e)=>{
     GetEmployee();
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get(handleUrl('yiemployment/delete_employee/'+id))
    .then(res=>{
        console.log(res);
        GetEmployee();
        setLoading(false)
        setAlertMessage("Employee deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }
  
  useEffect(()=>{
    document.title ="Dashboard | Employees"
    GetEmployee();
    GetProjectArea();
  },[current])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> Youth in Works</h4>
    <Link to={'/yiemployment/new'}>
      <button type="button" class="btn btn-outline-dark" style={{"marginRight":"40px"}}>New Employee</button>
    </Link>
    <button type="button" style={{"marginLeft":"60%"}}
          class="btn">Displaying { current} of { total } Results</button>
           <section className='row distribution'>
             
              <div className='col-md-2'>
                <Select title="Gender" 
                        dataKeys={["none","male","female"]} 
                        dataValues={["None","Male","Female"]}
                        handleChange={handleChange}
                        name='gender'
                />
              </div>
              <div className='col-md-2'>
                <Select title="Nationality" 
                        dataKeys={["none","host","refugee"]} 
                        dataValues={["None","Host","Refugee"]}
                        handleChange={handleChange}
                        name='nationality'
                />
            </div> 
            <div className='col-md-2'>
                <Select title="Disability status" 
                        dataKeys={["none","yes","no"]} 
                        dataValues={["None","Yes","No"]}
                        handleChange={handleChange}
                        name='disability'
                />
            </div> 
            <div className='col-md-3'>
                 <Select title="Project Area" 
                        dataKeys={projectArea.map((item)=>{ return item.id })} 
                        dataValues={projectArea.map((item)=>{ return item.name })}
                        handleChange={handleChange}
                        name='project_area'
                />
            </div> 
          
            <div className='col-md-1'>
              <button className='btn btn-dark'
                onClick={handleFilter}
              >Go</button>
            </div>
            
            <div className='col-md-2'>
                <Select title="Export Data" 
                        dataKeys={["pdf","csv"]} 
                        dataValues={["PDF","CSV"]}
                        handleChange={handleChange}
                        name='export'
                />
            </div> 
          </section> 
    <Alert message={alertMessage} />

    { loading?  <Spinner /> : 
           <table class="table card-table">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Fullname</th>
                 <th>Gender</th>
                 <th>Nationality</th>
                 <th>Project Area</th>
                 <th>Status</th>
                 <th>Actions</th>
               </tr>
             </thead>
             <tbody class="table-border-bottom-0">
             { UserData.map((item)=>{  
                 return <tr>
                   <td>{ item.id}</td>
                   <td><Link to={"/yiemployment/"+item.id}>{ item.fullname}</Link></td>
                   <td>{ item.gender}</td>
                   <td>{ item.residency_status}</td>
                   <td>{ item.proj_area}</td>
                   <td>{ (item.active == 1)? <span class="badge bg-label-success me-1">active</span>:
                   <span class="badge bg-label-success me-1"> inactive</span> }</td>
                   <td>
                   <div class="dropdown">
                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href={"/yiemployment/edit/"+item.id}
                    ><i class="bx bx-edit-alt me-1"></i> Edit</a
                  >
                  {role =="Super_admin"?
                      <a class="dropdown-item" href="javascript:void(0);"
                        onClick={()=>handleDelete(item.id)}
                        ><i class="bx bx-trash me-1"></i> Delete</a
                      >:""}
                </div>
              </div>
                   </td>
                 </tr>
               }) }
               </tbody>
               </table>
             }
             <Pagination 
             current={current} 
             total={total} 
             handleNextPage={handleNextPage} 
             handlePrevPage={handlePrevPage} 
             handleFirstPage={handleFirstPage} 
             handleLastPage={handleLastPage} 
             handlePage={handlePage} 
         />
     </div>
</Layout>
  )
}

export default Yiemployment