import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'
import Alert from '../../components/alert/Alert';
import Pagination from '../../components/pagination';
import Select from '../../components/select'
import Input from '../../components/input/Input'
import useActions from '../../constants'

const Aeplearners = () => {

  const role = localStorage.getItem("role");
  const [LearnerData, setLearnerData] = useState([])
  const { alertMessage, current, handleAlert, handleFirstPage, handleLastPage,
   handleNextPage, handlePage, handlePrevPage, handleUrl, loading,
   setAlertMessage, setCurrent, setLoading, dash_id} = useActions()
  
   const [total, setTotal] =useState(0)
   const [formData, setFormData] =useState({
        year:0,
        gender:"",
        fullname:"",
        nationality:"",
        disability:"",
        export:""
    })

    const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
   }

   const handleExport=(e)=>{

    window.open(handleUrl('aeplearners/export_learners/'+dash_id+
      "&name="+formData.fullname+"&gender="+formData.gender+"&nationality="+formData.nationality+
      "&disability="+formData.disability+"&export="+e.target.value), "_blank", "noreferrer")
   }

  const GetLearner =(e) =>{
    setLoading(true)
      axios.get(handleUrl('aeplearners/find_learners/'+dash_id+'&g='+current+
      "&name="+formData.fullname+"&gender="+formData.gender+"&nationality="+formData.nationality+
      "&disability="+formData.disability))
    .then(res=>{
        console.log(res);
        setLearnerData(res.data.data)
        setCurrent(res.data.pagination.current)
        setTotal(res.data.pagination.total)

        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get(handleUrl('aeplearners/delete_learners/'+id))
    .then(res=>{
        console.log(res);
        GetLearner();
        setLoading(false)
        setAlertMessage("learner deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }
  
  const handleFilter=(e)=>{
    GetLearner();
  }

  useEffect(()=>{
    document.title ="Dashboard | Learner"
    GetLearner();
  },[current])

  return (
    <Layout>
         <div class="table-responsive text-nowrap">
         <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> AEP Students
         </h4>
         <Link to={'/aeplearners/new'}><button type="button" 
          class="btn btn-outline-dark">New Student</button></Link>
          <button type="button" style={{"marginLeft":"65%"}}
          class="btn">Displaying { current} of { total } Results</button>
          <section className='row distribution'>
          <div className='col-md-2'>
                <Input title="Filter By Name" 
                        handleChange={handleChange}
                        name='fullname'
                        type='text'
                />
               </div>
              <div className='col-md-2'>
                <Select title="Gender" 
                        dataKeys={["male","female"]} 
                        dataValues={["Male","Female"]}
                        handleChange={handleChange}
                        name='gender'
                />
              </div>
              <div className='col-md-2'>
                <Select title="Nationality" 
                        dataKeys={["host","refugee"]} 
                        dataValues={["Host","Refugee"]}
                        handleChange={handleChange}
                        name='nationality'
                />
            </div> 
            <div className='col-md-2'>
                <Select title="Disability status" 
                        dataKeys={["yes","no"]} 
                        dataValues={["Yes","No"]}
                        handleChange={handleChange}
                        name='disability'
                />
            </div> 
          
            <div className='col-md-2'>
              <button className='btn btn-dark'
                onClick={handleFilter}
              >Go</button>
            </div>
            
            <div className='col-md-2'>
                <Select title="Export Data" 
                        dataKeys={["pdf","csv"]} 
                        dataValues={["PDF","CSV"]}
                        handleChange={handleExport}
                        name='export'
                />
            </div> 
          </section> 
         
         <Alert message={alertMessage} />
         { loading?  <Spinner /> : 
                <table class="table card-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Fullname</th>
                      <th>Gender</th>
                      <th>Mobile</th>
                      <th>Enrollment Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                  { LearnerData.map((item)=>{  
                      return <tr>
                        <td>{ item.id}</td>
                        <td><Link to={"/aeplearners/"+item.id}>{ item.fullname}</Link></td>
                        <td>{ item.gender}</td>
                        
                        <td>{ item.mobile}</td>
                        <td>{ item.date_enrolled}</td>
                        <td>{ (item.status == 1)? <span class="badge bg-label-success me-1">active</span>:
                        <span class="badge bg-label-success me-1"> inactive</span> }</td>
                        <td>
                        <div class="dropdown" >
                     <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                       <i class="bx bx-dots-vertical-rounded"></i>
                     </button>
                     <div class="dropdown-menu">
                       <a class="dropdown-item" href={"/aeplearners/edit/"+item.id}
                         ><i class="bx bx-edit-alt me-1"></i> Edit</a
                       >
                       {role =="Super_admin"?
                      <a class="dropdown-item" href="javascript:void(0);"
                        onClick={()=>handleDelete(item.id)}
                        ><i class="bx bx-trash me-1"></i> Delete</a
                      >:""}
                     </div>
                   </div>
                        </td>
                      </tr>
                    }) }
                    </tbody>
                    </table>
                  }
                  <Pagination 
                      current={current} 
                      total={total} 
                      handleNextPage={handleNextPage} 
                      handlePrevPage={handlePrevPage} 
                      handleFirstPage={handleFirstPage} 
                      handleLastPage={handleLastPage} 
                      handlePage={handlePage} 
                  />
          </div>
    </Layout>
  )
}

export default Aeplearners