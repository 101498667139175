import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'
import useActions from '../../../constants';

const Index = () => {

  const { alertMessage, current,
    handleAlert, handleFirstPage, handleLastPage,
   handleNext, handlePage, handlePrevious, handleUrl, loading,
   setAlertMessage, setCurrent, setLoading,
   setTotal, total, dash_id, prog_id} = useActions()

   const [formData, setFormData] =useState({
    dash_id,
    prog_id
  })

  const handleChange =(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
    console.log("form",formData)
 }

    const handleSubmit = (e)=>{
      e.preventDefault();
      setLoading(true)
       axios.post(handleUrl('dash_auth/create/'), formData)
       .then(res=>{
           console.log(res);
           setAlertMessage("Staff created Successfully");
           handleAlert();
           setLoading(false);
       })
       .catch(error=>{
         console.log(error);
         setLoading(false)
       })
    }

    useEffect(()=>{
      document.title = 'Dolph max | Staff New';
    },[])

  return (
   <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> Staff</h4>
    </div>
        <div class="row">
                <div class="col-md-12">
                  <div class="card mb-4">
                  <div class="card-header d-flex justify-content-between align-items-center">
       <h5 class="mb-0">New Staff Member </h5>
       <small class="text-muted float-end">Create a new member</small>
     </div>
     
     <div class="card-body">
       <Alert message={alertMessage} />
       { loading? <Spinner />: <form method='POST'> 
       <section className='row' >
          <div className='col-md-6'>
          <Input title={'Full name'} 
                  placeholder={'This is the members fullname'} 
                  handleChange={handleChange}
                  name={'fullname'}
                  value={formData.fullname}
          />
          </div>

          <div className='col-md-6'>
                <Input title={'Email'} 
                    placeholder={'Your email address'} 
                    handleChange={handleChange}
                    type='email'
                    name={'email'}
                    value={formData.email}

                />
          </div>
       </section>
       <section className='row' >
               <div className='col-md-6'>
                    <Select title="Gender" 
                        dataKeys={["male","female"]} 
                        dataValues={["Male","Female"]}
                        handleChange={handleChange}
                        name='gender'
                        value={formData.gender}
                  />
              </div>
              <div className='col-md-6'>
                               <Input title={'Position'} 
                                    placeholder={'Your position'} 
                                    handleChange={handleChange}
                                    type='text'
                                    name={'position'}
                                    value={formData.position}

                               />
                         </div>
        </section>
       <section className='row' >
          <div className='col-md-6'>
          <Input title={'Password'} 
                  placeholder={'This is the members password'} 
                  handleChange={handleChange}
                  name={'password'}
                  type="password"
                  value={formData.password}
          />
          </div>

          <div className='col-md-6'>
                <Input title={'Confirm Password'} 
                    placeholder={'Your password'} 
                    handleChange={handleChange}
                    type='password'
                    name={'confirm_password'}
                    value={formData.confirm_password}

                />
          </div>
       </section>
       <section className='row' >
       <div className='col-md-6' style={{"display":"flex","height":"40px"}}>
       <Select title="User Role" 
               dataKeys={["admin","editor","guest"]}
               dataValues={["admin","editor","guest"]}
               handleChange={handleChange}
               name='role'
               selected={formData.role}
         />   
        </div>
            
        <div className='col-md-6' style={{"display":"flex","height":"40px"}}>
        <Select title="Status" 
               dataKeys={["active","inactive"]}
               dataValues={["active","inactive"]}
               handleChange={handleChange}
               name='status'
               selected={formData.status}
         />   
        </div>
        </section>
        </form>
}
        </div> <div className='nav' >
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
                   </div>
                 

                </div>
              </div>
      </Layout>
  )
}

export default Index