import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'
import useActions from '../../../constants';

const Index = () => {
    
  const { alertMessage, current,
    handleAlert, handleNext, handlePrevious, handleUrl, loading,
   setAlertMessage, step, setLoading} = useActions()

    const [nationality, setNationality] = useState([])
    const [disability, setDisability] = useState([])
    const [projectArea, setProjectArea] = useState([])

    const [formData, setFormData] =useState({
    })

    const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
   }

      const GetNationality =(e) =>{
        setLoading(true)
          axios.get(handleUrl('nationality/find_all'))
        .then(res=>{
          //  console.log("from nationality",res.data);
            setNationality(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetProjectArea =(e) =>{
        setLoading(true)
          axios.get(handleUrl('project_area/find_all'))
        .then(res=>{
            console.log("from area",res.data);
            setProjectArea(res.data.data)
            setLoading(false)
            
        })
        .catch(error=>{
          console.log(error);
        })
      }
      
      
      const GetDisability =(e) =>{
        setLoading(true)
          axios.get(handleUrl('disability/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setDisability(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true)
         axios.post(handleUrl('yiemployment/create/'), formData)
         .then(res=>{
             console.log(res);
             setAlertMessage("Member Updated Successfully");
             handleAlert();
             setLoading(false);
         })
         .catch(error=>{
           console.log(error);
           setLoading(false)
         })
      }

      useEffect(()=>{
        document.title = 'Dolph max | Youth in employment';
          GetNationality();
          GetDisability();
          GetProjectArea();
      },[])

  return (
    <Layout>
    <div class="table-responsive text-nowrap">
       <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /Youth in Employment/</span> New</h4>
    </div>
    <article className={step==1?'step active': 'step'}>
        <h5>Step 1</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">New Youth</h5>
                      <small class="text-muted float-end">Create an Youth in employment</small>
                    </div>
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                         <div className='col-md-6'>
                             <Input title={'Full name'} 
                                    placeholder={'This is the members fullname'} 
                                    handleChange={handleChange}
                                    name={'fullname'}
                                    value={formData.fullname}
                                    required='true'
                            />
                         </div>

                         <div className='col-md-6'>
                         <Select title="Gender" 
                                      dataKeys={["male","female"]} 
                                      dataValues={["Male","Female"]}
                                      handleChange={handleChange}
                                      name='gender'
                                      value={formData.gender}
                                      required='true'

                                />
                        
                         </div>
                      </section>
                      <section className='row' >
                         <div className='col-md-6'>
                               <Input title={'Email'} 
                                    placeholder={'Your email address'} 
                                    handleChange={handleChange}
                                    type='email'
                                    name={'email'}
                                    value={formData.email}

                               />
                         </div>
                           
                         <div className='col-md-6'>
                                <Input title={'Mobile Number'} 
                                    placeholder={'+256756961542'} 
                                    handleChange={handleChange}
                                    type='mobile'
                                    name={'mobile'}
                                    value={formData.mobile}

                               />
                         </div>
                       </section>
                      <section className='row' >
                         <div className='col-md-6'>
                               <Input title={'Date of Birth'} 
                                    placeholder={'Date of birth'} 
                                    handleChange={handleChange}
                                    type='date'
                                    name={'date_of_birth'}
                                    value={formData.date_of_birth}
                                    required='true'

                               />
                         </div>
                         <div className='col-md-6'>
                                <Input title={'Enrolled Date'} 
                                    placeholder={'This is the date of entry'} 
                                    handleChange={handleChange}
                                    type='date'
                                    name={'enrolled_date'}
                                    value={formData.enrolled_date}
                                    required='true'
                                />
                                </div>
                        
                       </section>
                      
                      </form>
                     }
                    </div>
                  </div>
                </section>

        <button type="button" class="btn btn-dark" style={{"marginLeft":"92%"}} onClick={handleNext}>Next</button>


      </article>
      <article className={step==2?'step active': 'step'}>
        <h5>Step 2</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                  
                    <div class="card-body">
                      { loading? <Spinner />: <form onSubmit={handleSubmit} method='POST'> 
                      <section className='row' >
                      <div className='col-md-6'>
                               <Select title="Residency Status" 
                                      dataKeys={["national","refugee"]} 
                                      dataValues={["National","refugee"]}
                                      handleChange={handleChange}
                                      name='residency_status'
                                      selected={formData.residency_status}
                                      required='true'

                                />
                         </div>
                         <div className='col-md-6'>
                            { formData.residency_status =='refugee'? <     Select title="Nationality" 
                                      dataKeys={nationality.map((item)=>{ return item.id })} 
                                      dataValues={nationality.map((item)=>{ return item.country })}
                                      handleChange={handleChange}
                                      name='nationality_id'
                                      selected={formData.nationality_id}
                                />:""
                            }
                            </div>
                        
                       
                        </section>
                        <section className='row' >
                              
                               <div className='col-md-6'>  
                                    <Select title="marital status" 
                                        dataKeys={["single","married"]} 
                                        dataValues={["Single","married"]}
                                        handleChange={handleChange}
                                        name='marital_status'
                                        selected={formData.marital_status}

                                    />
                                </div>
                                <div className='col-md-6'>  
                                    <Input title={'Settlement'} 
                                        placeholder={'This is the identity number'} 
                                        handleChange={handleChange}
                                        name={'settlement'}
                                        value={formData.settlement}
                                        required='true'
                                    />
                               </div>
                            </section>
                            <section className='row' >
                              
                               <div className='col-md-6'>  
                                    <Select title="Project Area" 
                                        dataKeys={projectArea.map((item)=>{ return item.id })}
                                        dataValues={projectArea.map((item)=>{ return item.name })}
                                        handleChange={handleChange}
                                        name='project_area_id'
                                        selected={formData.project_area_id}
                                        required='true'
                                    />
                                </div>
                               
                            </section>
                            </form>
                     }
                    </div>
                  </div>
                </section>
        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
           </div>
           
      </article>
    
      <article className={step==3?'step active': 'step'}>
         <h5>Step 3</h5>
         <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                        <section className='row' >
                              <div className='col-md-6'>
                                <Select title="Any Disability" 
                                      dataKeys={["yes","no"]} 
                                      dataValues={["yes","no"]}
                                      handleChange={handleChange}
                                      name='any_disability'
                                      selected={formData.any_disability}
                                      required='true'

                                />
                               </div>
                               { formData.any_disability =='yes'?(
                               <div className='col-md-6'>
                                    <Select title="Disability Type" 
                                        dataKeys={disability.map((item)=>{ return item.id })} 
                                        dataValues={disability.map((item)=>{ return item.name })}
                                        handleChange={handleChange}
                                        name='disability_id'
                                        selected={formData.disability_id}
                                    />
                                </div>):""
                                }
                            </section> 
                            <section className='row' >
                              <div className='col-md-6'>
                              <Input title={'Employers full Name'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'employer_name'}
                                            value={formData.employer_name}
                                            required='true'
                                    />
                               </div>
                               <div className='col-md-6'>
                               <Input title={'Employers Mobile'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'employer_mobile'}
                                            value={formData.employer_mobile}
                                            required='true'
                                    />
                                </div>
                            </section>  
                            <section className='row' >
                              <div className='col-md-6'>
                              <Input title={'Employers Village'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'employer_village'}
                                            value={formData.employer_village}
                                            required='true'
                                    />
                               </div>
                               <div className='col-md-6'>
                               <Input title={'Employers Parish'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'employer_parish'}
                                            value={formData.employer_parish}
                                            required='true'
                                    />
                                </div>
                            </section>    
                        </form>
                     }
                    </div>
                  </div>
                </section>
         <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
        </div>
      </article>
      <article className={step==4?'step active': 'step'}>
         <h5>Step 4</h5>
         <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                        <section className='row' >
                              <div className='col-md-6'>
                              <Input title={'Employers Sub County'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'employer_subcounty'}
                                            value={formData.employer_subcounty}

                                    />
                               </div>
                               <div className='col-md-6'>
                               <Input title={'Youths other village'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'other_village'}
                                            value={formData.other_village}

                                    />
                                </div>
                            </section> 
                            <section className='row' >
                              <div className='col-md-6'>
                                  <Input title={'Youths Other subcounty'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'other_subcounty'}
                                            value={formData.other_subcounty}
                                  />
                               </div>
                               <div className='col-md-6'>
                               <Input title={"Next of kin's mobile"} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'next_of_kin'}
                                            value={formData.next_of_kin}
                                            required='true'
                                    />
                                </div>
                            </section>
                            <section className='row' >
                              <div className='col-md-6'>
                              <Select title="Earnings" 
                                        dataKeys={["monthly","weekly","daily","irregular"]} 
                                        dataValues={["Monthly","Weekly","Daily","Irregular"]}
                                        handleChange={handleChange}
                                        name='earnings'
                                        selected={formData.earnings}
                                        required='true'

                                    />
                               </div>
                               <div className='col-md-6'>
                               <Input title={'Amount'} 
                                      placeholder={'This is the identity number'} 
                                      handleChange={handleChange}
                                      type="number"
                                      name={'amount'}
                                      value={formData.amount}
                                      required='true'
                                    />
                                </div>
                            </section>  
                           
                        </form>
                     }
                    </div>
                  </div>
                </section>
         <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
        </div>
      </article>
      <article className={step==5?'step active': 'step'}>
         <h5>Summary</h5>

         <Alert message={alertMessage} />

         <table class="table card-table">
                 <tbody class="table-border-bottom-0">
                    <tr>
                      <th>Fullname:</th>
                      <th>{formData.fullname}</th>
                    </tr>
                    <tr>
                      <th>Gender:</th>
                      <th>{formData.gender}</th>
                    </tr>
                    <tr>
                      <th>Email Address:</th>
                      <th>{formData.email}</th>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <th>{formData.mobile}</th>
                    </tr>
                    <tr>
                      <th>Date of Birth:</th>
                      <th>{formData.date_of_birth}</th>
                    </tr>
                    <tr>
                      <th>Date of Enrollement:</th>
                      <th>{formData.enrolled_date}</th>
                    </tr>
                    <tr>
                      <th>Residency Status:</th>
                      <th>{formData.residency_status}</th>
                    </tr>
                    <tr>
                      <th>Nationality:</th>
                      <th>{nationality.map((item)=>{ if(item.id == formData.nationality_id){ return item.country }})}</th>
                    </tr>
                    <tr>
                      <th>Marital Status:</th>
                      <th>{formData.marital_status}</th>
                    </tr>
                    <tr>
                      <th>Settlement:</th>
                      <th>{formData.settlement}</th>
                    </tr>
                    <tr>
                      <th>Project Area:</th>
                      <th>{projectArea.map((item)=>{ if(item.id == formData.project_area_id){ return item.name }})}</th>
                    </tr>
                    <tr>
                      <th>any_disability:</th>
                      <th>{formData.any_disability}</th>
                    </tr>
                    <tr>
                      <th>Disability:</th>
                      <th>{disability.map((item)=>{ if(item.id == formData.disability_id){ return item.name }})}</th>
                    </tr>
                    <tr>
                      <th>Employers Fullname:</th>
                      <th>{formData.employer_name}</th>
                    </tr>
                    <tr>
                      <th>Employers Mobile:</th>
                      <th>{formData.employer_mobile}</th>
                    </tr>
                    <tr>
                      <th>Employers Village:</th>
                      <th>{formData.employer_village}</th>
                    </tr>
                    <tr>
                      <th>Employers Parish:</th>
                      <th>{formData.employer_parish}</th>
                    </tr>
                    <tr>
                      <th>Employers SubCounty:</th>
                      <th>{formData.employer_subcounty}</th>
                    </tr>
                    <tr>
                      <th>Youths Other Village</th>
                      <th>{formData.other_village}</th>
                    </tr>
                    <tr>
                      <th>Youths Other Subcounty</th>
                      <th>{formData.other_subcounty}</th>
                    </tr>
                    <tr>
                      <th>Next of Kin</th>
                      <th>{formData.next_of_kin}</th>
                    </tr>
                    <tr>
                      <th>Earnings</th>
                      <th>{formData.earnings}</th>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <th>{formData.amount}</th>
                    </tr>
                    </tbody>
                    </table>
                    
           <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
      </article>
    </Layout>
  )
}

export default Index