import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'
import Pagination from '../../components/pagination'
import Select from '../../components/select'
import Input from '../../components/input/Input'
import useActions from '../../constants'

const Employers = () => {
  const [employersData, setEmployersData] = useState([])
  const { alertMessage, current, formData,
    handleAlert, handleChange, handleFirstPage, handleLastPage,
   handleNextPage, handlePage, handlePrevPage, handleUrl, loading,
   setAlertMessage, setCurrent, setFormData, setLoading,
   setTotal, total} = useActions()

  const GetEmployers =(e) =>{
    const dash_id = localStorage.getItem("dash_id");

    setLoading(true)
      axios.get(handleUrl('employer/find_all&g='+current))
    .then(res=>{
        console.log(res);
        setEmployersData(res.data.data)
        setLoading(false)
        setCurrent(res.data.pagination.current)
        setTotal(res.data.pagination.total)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }
  
  useEffect(()=>{
    document.title ="Dashboard | Employers"
    GetEmployers();
  },[current])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> Employers</h4>
    <Link to={'/employer/new'}><button type="button" class="btn btn-outline-dark">New Employer</button></Link>
    <button type="button" style={{"marginLeft":"75%"}}
          class="btn">Page { current} of { total }</button>
       <section className='row distribution'>
             <div className='col-md-2'>
                <Input title="Filter By Reciepient" 
                        handleChange={handleChange}
                        name='reciepient'
                        type='text'
                />
               </div>
              <div className='col-md-2'>
                <Select title="Type" 
                        dataKeys={["none","male","female"]} 
                        dataValues={["None","Male","Female"]}
                        handleChange={handleChange}
                        name='gender'
                />
              </div>
              <div className='col-md-2'>
                <Select title="Items" 
                        dataKeys={["none","male","female"]} 
                        dataValues={["None","Male","Female"]}
                        handleChange={handleChange}
                        name='gender'
                />
            </div> 
            <div className='col-md-2'>
                <Input title="Date" 
                        handleChange={handleChange}
                        type='date'
                        name='date'
                />
            </div> 
          
            <div className='col-md-2'>
              <button className='btn btn-dark'>Go</button>
            </div>
            
            <div className='col-md-2'>
                <Select title="Export Data" 
                        dataKeys={["pdf","csv"]} 
                        dataValues={["PDF","CSV"]}
                        handleChange={handleChange}
                        name='export'
                />
            </div> 
          </section> 
    { loading?  <Spinner /> : 
           <table class="table card-table">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Employer</th>
                 <th>Mobile</th>
                 <th>No. of Youth</th>
                 <th>Status</th>
                 <th>Actions</th>
               </tr>
             </thead>
             <tbody class="table-border-bottom-0">
             { employersData.map((item)=>{  
                 return <tr>
                   <td>{ item.id}</td>
                   <td>{ item.e_fullname}</td>
                   <td><Link to={"/employers/"+item.id}>{ item.e_telephone}</Link></td>
                   <td>{ item.total}</td>
                   <td>{ (item.active == 1)? <span class="badge bg-label-success me-1">active</span>:
                   <span class="badge bg-label-success me-1"> inactive</span> }</td>
                   <td>
                   <div class="dropdown">
                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href={"/pages/edit/"+item.id}
                    ><i class="bx bx-edit-alt me-1"></i> Edit</a
                  >
                  <a class="dropdown-item" href="javascript:void(0);"
                    ><i class="bx bx-trash me-1"></i> Delete</a
                  >
                </div>
              </div>
                   </td>
                 </tr>
               }) }
               </tbody>
               </table>
             }
             <Pagination 
             current={current} 
             total={total} 
             handleNextPage={handleNextPage} 
             handlePrevPage={handlePrevPage} 
             handleFirstPage={handleFirstPage} 
             handleLastPage={handleLastPage} 
             handlePage={handlePage} 
         />
     </div>
</Layout>
  )
}

export default Employers