import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";
import useActions from '../../../constants';

const Index = () => {

  const { alertMessage, handleAlert, handleChange, handleNext,
     handlePrevious, handleUrl, loading, setAlertMessage,
      setLoading, step, dash_id, prog_id, staff_id} = useActions()

    const {id} = useParams()

    const [formData, setFormData] =useState({
      fullname:"",
      enrollment_date:"",
      gender:"none",
      date_of_birth:"",
      email:"",
      mobile:"",
      residency_status:"none",
      nationality_id:"0",
      national_id_number:"",
      marital_status:"none",
      any_children:"none",
      biological_children_no:"",
      dependent_children_no:"",
      children_no:"",
      age_of_youngest:"",
      currently_living_with:"my parents",
      any_disability:"",
      disability_id:"",
      currently_living_with:"",
      hear_about:"",
      last_class_attended:"",
      aep_level:"",
      plans_after_completing:"",
      dash_id,
      prog_id,
      staff_id
  })

    const [nationality, setNationality] = useState([])
    const [disability, setDisability] = useState([])

      const GetLearner =(e) =>{
        setLoading(true)
          axios.get(handleUrl('aeplearners/find_learners_by_id/'+id))
        .then(res=>{
            setFormData(res.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }
      
      const GetNationality =(e) =>{
        setLoading(true)
          axios.get(handleUrl('nationality/find_all'))
        .then(res=>{
          //  console.log("from nationality",res.data);
            setNationality(res.data.data)
            setLoading(false)
            
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const GetDisability =(e) =>{
        setLoading(true)
          axios.get(handleUrl('disability/find_all'))
        .then(res=>{
            //console.log("from disability",res.data);
            setDisability(res.data.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
        })
      }

      const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true)
         axios.post(handleUrl('aeplearners/update/'+id), formData)
         .then(res=>{
             console.log("update",res.data);
             setAlertMessage("Student updated Successfully");
             handleAlert();
             setLoading(false);
         })
         .catch(error=>{
           console.log("err:",error);
           setLoading(false)
         })
      }

      useEffect(()=>{
        document.title = 'Dolph max | Student Edit';
          GetNationality();
          GetDisability();
          GetLearner();
      },[])

      console.log(formData)

  return (
    <Layout>
    <div class="table-responsive text-nowrap">
       <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard / AEP Students /</span> Edit</h4>
    </div>
    <article className={step==1?'step active': 'step'}>
        <h5>Step 1</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">Edit Student </h5>
                      <small class="text-muted float-end">Edit an AEP student</small>
                    </div>
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                         <div className='col-md-6'>
                             <Input title={'Full name'} 
                                    placeholder={'This is the members fullname'} 
                                    handleChange={handleChange}
                                    name={'fullname'}
                                    value={formData.fullname}
                            />
                         </div>
                         <div className='col-md-6'>
                         <Input title={'Enrollment Date'} 
                                    placeholder={'This is the date of entry'} 
                                    handleChange={handleChange}
                                    type='date'
                                    name={'enrollment_date'}
                                    value={formData.enrollment_date}

                            />
                         </div>
                      </section>
                      <section className='row' >
                         <div className='col-md-6'>
                                <Select title="Gender" 
                                      dataKeys={["none","male","female"]} 
                                      dataValues={["None","Male","Female"]}
                                      handleChange={handleChange}
                                      name='gender'
                                      selected={formData.gender}
                                />
                         </div>
                           
                         <div className='col-md-6'>
                               <Input title={'Date of Birth'} 
                                    placeholder={'This is the date of birth'} 
                                    handleChange={handleChange}
                                    type='date'
                                    name={'date_of_birth'}
                                    value={formData.date_of_birth}
                            />
                         </div>
                       </section>
                       <section className='row' >
                         <div className='col-md-6'>
                               <Input title={'Email'} 
                                    placeholder={'Your email address'} 
                                    handleChange={handleChange}
                                    type='email'
                                    name={'email'}
                                    value={formData.email}

                               />
                         </div>
                           
                         <div className='col-md-6'>
                                <Input title={'Mobile Number'} 
                                    placeholder={'+256756961542'} 
                                    handleChange={handleChange}
                                    type='mobile'
                                    name={'mobile'}
                                    value={formData.mobile}

                               />
                         </div>
                       </section>
                      </form>
                     }
                    </div>
                  </div>
                </section>

        <button type="button" class="btn btn-dark" style={{"marginLeft":"92%"}} onClick={handleNext}>Next</button>
      </article>
      <article className={step==2?'step active': 'step'}>
        <h5>Step 2</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                  
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                      <section className='row' >
                         <div className='col-md-6'>
                            <Select title="Residency Status" 
                                    dataKeys={["none","host","Refugee"]} 
                                    dataValues={["none","host","Refugee"]}
                                    handleChange={handleChange}
                                    name='residency_status'
                                    selected={formData.residency_status}
                            />
                          </div>
                          <div className='col-md-6'>
                          <Select title="Nationality" 
                                      dataKeys={nationality.map((item)=>{ return item.id })} 
                                      dataValues={nationality.map((item)=>{ return item.country })}
                                      handleChange={handleChange}
                                      name='nationality_id'
                                      selected={formData.nationality_id}
                                />
                                </div>
                        </section>
                        <section className='row' >
                              <div className='col-md-6'>  
                                    <Input title={'National ID Number (NIN)'} 
                                            placeholder={'This is the identity number'} 
                                            handleChange={handleChange}
                                            name={'national_id_number'}
                                            value={formData.national_id_number}
                                    />
                               </div>
                               <div className='col-md-6'>  
                                    <Select title="marital status" 
                                        dataKeys={["single","living together/married","Divorced/Separeted","widowed"]} 
                                        dataValues={["Single","Living together/married","Divorced/Separeted","Widowed"]}
                                        handleChange={handleChange}
                                        name='marital_status'
                                        selected={formData.marital_status}

                                    />
                                </div>
                            </section>
                            </form>
                     }
                    </div>
                  </div>
                </section>
        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
           </div>
           
      </article>
      <article className={step==3?'step active': 'step'}>
        <h5>Step 3</h5>
        <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-body">
                      { loading? <Spinner />: <form method='POST'> 
                        <section className='row' >
                              <div className='col-md-6'>
                                <Select title="Any Children" 
                                      dataKeys={["yes","no"]} 
                                      dataValues={["Yes","No"]}
                                      handleChange={handleChange}
                                      name='any_children'
                                      selected={formData.any_children}
                                />
                            </div>
                            <div className='col-md-6'>
                                <Input title={'Biological Children Number'} 
                                    placeholder={'Only Children less than 18years born of the learner'} 
                                    handleChange={handleChange}
                                    type='number'
                                    name={'biological_children_no'}
                                    value={formData.biological_children_no}
                                />
                            </div>
                        </section>
                        <section className='row' >
                              <div className='col-md-6'>
                                    <Input title={'Dependant Children Number'} 
                                            placeholder={'Only Children under the care of the learner'} 
                                            handleChange={handleChange}
                                            type='number'
                                            name={'dependent_children_no'}
                                            value={formData.dependent_children_no}
                                    />
                               </div>
                               <div className='col-md-6'>
                                   <Input title={'Children Number'} 
                                        placeholder={'This is the mobile number'} 
                                        handleChange={handleChange}
                                        name={'children_no'}
                                        type='number'
                                        value={formData.children_no}
                                   />
                               </div>
                        </section>
                        <section className='row' >
                              <div className='col-md-6'>
                                    <Input title={'Age of the Youngest'} 
                                            placeholder={'This is the mobile number'} 
                                            handleChange={handleChange}
                                            name={'age_of_youngest'}
                                            type='number'
                                            value={formData.age_of_youngest}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <Select title="Who are you currently living with" 
                                        dataKeys={["my parents","wife/husband and children","my brothers/sisters","my relatives","alone"]} 
                                        dataValues={["my parents","wife/husband and children","my brothers/sisters","my relatives","alone"]}
                                        handleChange={handleChange}
                                        name='currently_living_with'
                                        selected={formData.living_with}
                                    />
                          
                                </div>
                                </section>
                             </form>
                     }
                    </div>
                  </div>
                </section>
        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
        </div>
      </article>
      <article className={step==4?'step active': 'step'}>
         <h5>Step 4</h5>
         <section class="col-xll">
                  <div class="card mb-4">
                    <div class="card-body">
                    <Alert message={alertMessage} />
                      { loading? <Spinner />: <form method='POST'> 
                        <section className='row' >
                              <div className='col-md-6'>
                                <Select title="Any Disability" 
                                      dataKeys={["yes","no"]} 
                                      dataValues={["Yes","No"]}
                                      handleChange={handleChange}
                                      name='any_disability'
                                      selected={formData.any_disability}
                                />
                               </div>
                               <div className='col-md-6'>
                                    <Select title="Disability Type" 
                                        dataKeys={disability.map((item)=>{ return item.id })} 
                                        dataValues={disability.map((item)=>{ return item.name })}
                                        handleChange={handleChange}
                                        name='disability_id'
                                        selected={formData.disability_id}
                                    />
                                </div>
                            </section>
                            <section className='row' >
                              <div className='col-md-6'>
                                 
                                   <Select title={'How did you hear about bridge Project AEP'}
                                        dataKeys={["mother 2 mother club","youth education commitee","community education commitee","other sources"]} 
                                        dataValues={["mother 2 mother club","youth education commitee","community education commitee","other sources"]}
                                        handleChange={handleChange}
                                        name='hear_about'
                                        selected={formData.hear_about}
                                    />
                              </div>
                              <div className='col-md-6'>
                                 <Input title={'last class attended by the learner'}
                                        placeholder={'last Class attended'}
                                        handleChange={handleChange}
                                        name={'last_class_attended'}
                                        value={formData.last_class_attended}

                                />
                              </div>
                            </section>
                            <section className='row' >
                              <div className='col-md-6'>
                                 
                                   <Select title={'Plans after completing AEP'}
                                        dataKeys={["continue with further education","become self employed","look for a job","not sure"]} 
                                        dataValues={["continue with further education","become self employed","look for a job","not sure"]}
                                        handleChange={handleChange}
                                        name='plans_after_completing'
                                        selected={formData.plans_after_completing}
                                    />
                              </div>
                              <div className='col-md-6'>
                                   <Select title={'AEP level enrolled'}
                                        dataKeys={["level 1","level 2"]} 
                                        dataValues={["level 1","level 2"]}
                                        handleChange={handleChange}
                                        name='aep_level'
                                        selected={formData.aep_level}
                                    />
                              </div>
                            </section>

                        </form>
                     }
                    </div>
                  </div>
                </section>
         <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleNext}>Next</button>
        </div>
      </article>
      <article className={step==5?'step active': 'step'}>
         <h5>Summary</h5>
         <Alert message={alertMessage} />
         <table class="table card-table">
                 <tbody class="table-border-bottom-0">
                    <tr>
                      <th>Fullname:</th>
                      <th>{formData.fullname}</th>
                    </tr>
                    <tr>
                      <th>Enrollment Date:</th>
                      <th>{formData.enrollment_date}</th>
                    </tr>
                    <tr>
                      <th>Gender:</th>
                      <th>{formData.gender}</th>
                    </tr>
                    <tr>
                      <th>Date of Birth:</th>
                      <th>{formData.date_of_birth}</th>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <th>{formData.email}</th>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <th>{formData.mobile}</th>
                    </tr>
                    <tr>
                      <th>Residency Status:</th>
                      <th>{formData.residency_status}</th>
                    </tr>
                    <tr>
                      <th>Nationality:</th>
                      <th>{nationality.map((item)=>{ if(item.id == formData.nationality_id){ return item.country }})}</th>
                    </tr>
                    <tr>
                      <th>National ID Number:</th>
                      <th>{formData.national_id_number}</th>
                    </tr>
                    <tr>
                      <th>Marital Status:</th>
                      <th>{formData.marital_status}</th>
                    </tr>
                    <tr>
                      <th>Any Children:</th>
                      <th>{formData.any_children}</th>
                    </tr>
                    <tr>
                      <th>Biological Children Number:</th>
                      <th>{formData.biological_children_no}</th>
                    </tr>
                    <tr>
                      <th>Dependent Children Number:</th>
                      <th>{formData.dependent_children_no}</th>
                    </tr>
                    <tr>
                      <th>Number of Children:</th>
                      <th>{formData.children_no}</th>
                    </tr>
                    <tr>
                      <th>Age of Youngest:</th>
                      <th>{formData.age_of_youngest}</th>
                    </tr>
                    <tr>
                      <th>Your currently living with:</th>
                      <th>{formData.living_with}</th>
                    </tr>
                    <tr>
                      <th>Any Disability:</th>
                      <th>{formData.any_disability}</th>
                    </tr>
                    <tr>
                      <th>State if yes Disability:</th>
                      <th>{disability.map((item)=>{ if(item.id == formData.disability_id){ return item.name }})}</th>
                    </tr>
                    <tr>
                      <th>How did you hear about AEP:</th>
                      <th>{formData.hear_about}</th>
                    </tr>
                    <tr>
                      <th>Last Class Attended:</th>
                      <th>{formData.last_class_attended}</th>
                    </tr>
                    <tr>
                      <th>AEP level:</th>
                      <th>{formData.aep_level}</th>
                    </tr>
                    <tr>
                      <th>Plans After Completing:</th>
                      <th>{formData.plans_after_completing}</th>
                    </tr>                     
                  </tbody>
            </table>
           <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handlePrevious}>Previous</button>
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
      </article>
    </Layout>
  )
}

export default Index