import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom'
import Spinner from '../../components/spinner/Spinner'
import axios from 'axios'
import Layout from '../../templates/layout'

const Reports = () => {
  const token = localStorage.getItem("token");
   const [Reports, setReports] = useState([])
   const [loading, setLoading] = useState(false)
   
   const GetReports =(e) =>{
      setLoading(true)
        axios.get('http://localhost/vijana/busia/?p=reports/find_all&token='+token)
      .then(res=>{
          console.log(res);
          setReports(res.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
      })
    }
  
    useEffect(()=>{
      document.title ="Dashboard | Reports"
        GetReports();
    },[])

  return (
     <Layout>
        <div class="table-responsive text-nowrap">
         <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard /</span> Reports</h4>
           </div>
     </Layout>
  )
}

export default Reports