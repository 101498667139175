import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Textarea = ({title, titledesc, placeholder, value, handleChange, name}) => {
  return (
      <article>
          <label class="form-label" for="form-label">{title}</label>
          <textarea
              id="basic-icon-default-message"
              class="form-control"
              placeholder={placeholder}
              aria-label={ placeholder}
              aria-describedby="basic-icon-default-message2"
              value={value}
              name={name}
              onChange={(e)=>handleChange(e)}

          >{value}</textarea>
          <div id="floatingInputHelp" class="form-text">
            { titledesc }
          </div>
      </article>
  )
}

export default Textarea
