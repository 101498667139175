import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../../templates/layout'
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";
import useActions from '../../../constants'

const Index = () => {

  const {handleUrl, loading, setLoading, setTotal, total, dash_id} = useActions()

  const [userData, setDashData] = useState({})
  const {id} = useParams()

  const GetUser =(e) =>{
    setLoading(true)
      axios.get(handleUrl('dash_auth/find/'+id))
    .then(res=>{
        console.log("find user",res.data);
        setDashData(res.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  useEffect(()=>{
    document.title ="Dashboard | User"
    GetUser();
  },[])
  return (
    <Layout>
       <div class="table-responsive text-nowrap">
           <h4 class="fw-bold py-3 mb-2"><span class="text-muted fw-light">Dashboard / Staff</span> / View</h4>
        </div>
        <article className={'step active'}>
            <h5>Staff</h5>
            <table class="table card-table">
                    <tbody class="table-border-bottom-0">
                      <tr>
                        <th>Fullname:</th>
                        <th>{userData.fullname}</th>
                      </tr>
                      <tr>
                        <th>Email Address:</th>
                        <th>{userData.email}</th>
                      </tr>
                      <tr>
                        <th>Position:</th>
                        <th>{userData.position}</th>
                      </tr>
                      <tr>
                        <th>Gender:</th>
                        <th>{userData.gender}</th>
                      </tr>
                      <tr>
                        <th>Role:</th>
                        <th>{userData.role}</th>
                      </tr>
                      <tr>
                        <th>Status:</th>
                        <th>{userData.status}</th>
                      </tr>
                      </tbody>
               </table>
        </article>

    </Layout>
  )
}

export default Index